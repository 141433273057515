<template>
  <div>
    <v-layout wrap>
      <!-- Custom Buttons -->
      <v-flex v-for="customButton in customButtons" :key="customButton.name" md4 style="padding: 1px;">
        <v-card flat height="100%" ripple tile @click="clickCustomButton(customButton)">
          <p class="numpadText">{{ customButton.text }}</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('7')">
          <p class="numpadText">7</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('8')">
          <p class="numpadText">8</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('9')">
          <p class="numpadText">9</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('4')">
          <p class="numpadText">4</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('5')">
          <p class="numpadText">5</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('6')">
          <p class="numpadText">6</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('1')">
          <p class="numpadText">1</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('2')">
          <p class="numpadText">2</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('3')">
          <p class="numpadText">3</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="resetField">
          <p class="numpadText" style="color: #D92550;">CLR</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;" xs4>
        <v-card flat height="100%" ripple tile @click="clickButton('0')">
          <p class="numpadText">0</p>
        </v-card>
      </v-flex>

      <v-flex md4 style="padding: 1px;">
        <v-card flat height="100%" ripple tile @click="clickButton('.')">
          <p class="numpadText">,</p>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<style scoped>
.numpadText {
  user-select: none;
}

@media only screen and (max-width: 1100px) {
  .numpadText {
    font-family: Anton;
    font-size: 15px;
    text-align: center;
    padding-top: 10%;
  }
}

@media only screen and (min-width: 1101px) {
  .numpadText {
    font-family: Anton;
    font-size: 20px;
    text-align: center;
    padding-top: 10%;
  }
}
</style>

<script>
export default {
  name: "NumpadOnly",

  props: {
    customButtons: {
      default: () => {
        return [];
      },
      type: Array
    }
  },

  data() {
    return {
      value: ""
    }
  },

  watch: {
    value() {
      this.$emit("updateValue", this.value);
    }
  },

  methods: {
    clickButton(button) {
      if (button === '.' && this.value.includes('.'))
        return;
      this.value += button;

      this.$emit("clickButton", button);
    },
    clickCustomButton(customButton) {
      this.$emit("clickCustomButton", customButton);
    },
    resetField() {
      this.value = "";

      this.$emit("resetField");
    }
  }
}
</script>
