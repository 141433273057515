<template>
  <div>
    <v-dialog
      :value="voucherErrorMessage.length > 0"
      max-width="300"
    >
      <v-card>
        <v-card-title>{{ $t('generic.lang_info') }}</v-card-title>
        <v-card-text class="text-center">{{ voucherErrorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="voucherErrorMessage = ''"
            >{{ $t('generic.lang_understand') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ButtonsGiftCardDialog
      :gift-card-i-d="giftCardID"
      :pos-type="posType"
      :show-dialog="showGiftCardDialog"
      @closeDialog="showGiftCardDialog = false"
    ></ButtonsGiftCardDialog>
    <RetourDialog
      :invoice="retoureInvoiceData"
      :pos-type="posType"
      :show-dialog="showRetoureDialog"
      @closeDialog="showRetoureDialog = false"
    ></RetourDialog>

    <ItemVariationsDialog
      :item="item"
      :show-dialog="showVariationsDialog"
      @cancelDialog="cancelVariationsDialog"
      @close="showVariationsDialog = false"
      @selectVariation="variationSelected"
    ></ItemVariationsDialog>
    <ItemAdditionalBarcodesDialog
      :item="item"
      :show-dialog="showAddBarcodesDialog"
      @cancelDialog="cancelAddBarcodesDialog"
      @close="showAddBarcodesDialog = false"
      @selectAddBarcode="addBarcodeSelected"
    ></ItemAdditionalBarcodesDialog>
    <ItemPriceInputDialog
      v-if="item !== null"
      :item="item"
      :show-dialog="showPriceInputDialog"
      @cancelDialog="cancelPriceInputDialog"
      @changePrice="priceChanged"
      @closeDialog="showPriceInputDialog = false"
    />
    <ItemWeightInputDialog
      v-if="item !== null"
      :item="item"
      :show-dialog="showWeightInputDialog"
      @acceptWeight="weightAccepted"
      @cancelDialog="cancelWeightInputDialog"
      @closeDialog="showWeightInputDialog = false"
    />
    <ItemMealSizesDialog
      v-if="item !== null"
      :item="item"
      :show-dialog="showMealsizesDialog"
      @cancelDialog="cancelMealSizeDialog"
      @closeDialog="showMealsizesDialog = false"
      @selectMealsize="mealSizeSelected"
    />
    <ItemExtrasDialog
      v-if="item !== null && showExtrasDialog"
      :item="item"
      :itemgroup="itemgroup"
      :meal-size="mealSize"
      :show-dialog="showExtrasDialog"
      @cancelDialog="cancelExtrasDialog"
      @closeDialog="showExtrasDialog = false"
      @selectExtras="extrasSelected"
    />
    <ItemAgeVerificationDialog
      v-if="item !== null"
      :show-dialog="showAgeVerificationDialog"
      :item="item"
      :min-age="minVerifiedAge"
      @closeDialog="showAgeVerificationDialog = false"
      @acceptAge="acceptVerifyAge"
    ></ItemAgeVerificationDialog>
    <ItemWarenameBookingnumberDialog
      v-if="item !== null"
      :show-dialog="showWarenameBookingNumberDialog"
      :item="item"
      @closeDialog="showWarenameBookingNumberDialog = false"
      @cancelDialog="cancelWarenameBookingnumberDialog"
      @dataSaved="warenameBookingnumberSubmited"
    ></ItemWarenameBookingnumberDialog>

    <!-- serialNo dialog -->
    <serial-no-scanner-component
      :pos-type="this.posType"
      v-model="serialNoDialog"
      v-bind:item="this.item"
      @success="serialNoScanned"
      @failed="serialNoScannerFailed"
    />

    <ItemScaleComponent
      :toggle-scale="toggleScale"
      :item="item"
      :new-price="newPrice"
      :is-tara="isTara"
      @applyScale="applyScale"
      @cancelScale="cancelScale"
    />
    <ButtonsPagerDialog
      :pos-type="posType"
      :show-dialog="showPagerDialog"
      @closeDialog="pagerUpdated"
    />

    <eventbee-scanned-ticket-dialog
      ref="eventbeeScannedTicketDialog"
    ></eventbee-scanned-ticket-dialog>
  </div>
</template>

<script>
import ItemPriceInputDialog from '../../components/pos/item/ItemPriceInputDialog';
import ItemWeightInputDialog from '../../components/pos/item/ItemWeightInputDialog';
import ItemMealSizesDialog from '../../components/pos/item/ItemMealSizesDialog';
import ItemExtrasDialog from '../../components/pos/item/ItemExtrasDialog';
import ItemVariationsDialog from '../../components/pos/item/ItemVariationsDialog';
import ButtonsGiftCardDialog from '../../components/pos/buttons/ButtonsGiftCardDialog';
import RetourDialog from '../../components/pos/RetourDialog';
import ItemAgeVerificationDialog from '@/components/pos/item/ItemAgeVerificationDialog';
import ItemWarenameBookingnumberDialog from '@/components/pos/item/ItemWarenameBookingnumberDialog';
import { Events } from '@/plugins/events';
import { formatCurrency } from "../../plugins/currencyFormater";
import { mapGetters, mapState } from 'vuex';
import { ENDPOINTS } from '@/config';
import SerialNoScannerComponent from '@/components/pos/item/SerialNoScannerComponent';
import ItemScaleComponent from '../../components/pos/item/ItemScaleComponent';
import ButtonsPagerDialog from '../../components/pos/buttons/ButtonsPagerDialog';
import { calcGrossPrice, getNetPrice } from '../../plugins/tax';
import EventbeeScannedTicketDialog from '@/components/eventbee/pos/EventbeeScannedTicketDialog.vue';
import ItemAdditionalBarcodesDialog from "../../components/pos/item/ItemAdditionalBarcodesDialog";
import randomString from "randomstring";

export default {
  name: 'BookItem',

  components: {
    ItemAdditionalBarcodesDialog,
    ButtonsPagerDialog,
    ItemScaleComponent,
    SerialNoScannerComponent,
    ItemWarenameBookingnumberDialog,
    ItemAgeVerificationDialog,
    RetourDialog,
    ButtonsGiftCardDialog,
    ItemVariationsDialog,
    ItemExtrasDialog,
    ItemMealSizesDialog,
    ItemWeightInputDialog,
    ItemPriceInputDialog,
    EventbeeScannedTicketDialog,
  },

  props: {
    posType: String,
  },

  data() {
    return {
      serialNoDialog: false,
      waitForSerialNoScannerResolver: null,
      waitForSerialNoScannerRejecter: null,
      waitForSerialNoDialogResolver: null,
      waitForSerialNoDialogRejecter: null,
      successSignal: null,
      errorSignal: null,
      item: null,
      itemgroup: null,
      // DIALOGS //
      showVariationsDialog: false,
      showAddBarcodesDialog: false,
      showPriceInputDialog: false,
      showWeightInputDialog: false,
      showExtrasDialog: false,
      showMealsizesDialog: false,
      showRetoureDialog: false,
      showAgeVerificationDialog: false,
      showWarenameBookingNumberDialog: false,
      showGiftCardDialog: false,
      showPagerDialog: false,
      toggleScale: false,
      isTara: false,
      //
      ageVerified: false,
      hasHappyHourOrOffer: false,
      minVerifiedAge: 0,
      newPrice: null,
      weight: null,
      isWeighted: false,
      selectedExtras: null,
      mealSize: null,
      voucherErrorMessage: '',
      giftCardID: '',
      retoureInvoiceData: null,
      warenameOverwrite: null,
      bookingNumber: null,
      selectedCourse: null,
      scalePositionId: null,
    };
  },

  computed: {
    ...mapState(['pos', 'settings', 'extras', 'courses']),
    ...mapGetters({
      gastroTotalPrice: 'pos/gastro/totalPrice',
      retailTotalPrice: 'pos/retail/totalPrice',
      wholesaleRetailTotalPrice: 'pos/wholesaleRetail/totalPrice',
      retailGoodsValue: 'pos/retail/goodsValue',
      gastroGoodsValue: 'pos/gastro/goodsValue',
      wholesaleRetailGoodsValue: 'pos/wholesaleRetail/goodsValue',
      isScaleEnabled: 'scale/isEnabled',
    }),
    orderedItems() {
      if (this.posType === 'retail') {
        return this.pos.retail.orderedItems;
      } else if (this.posType === 'wholesaleRetail') {
        return this.pos.wholesaleRetail.orderedItems;
      } else if (this.posType === 'gastro') {
        if (this.pos.gastro.party !== null) {
          if (this.pos.gastro.party.hasOwnProperty('name')) {
            if (
              this.pos.gastro.orderedItems.hasOwnProperty(
                this.pos.gastro.party.name
              )
            ) {
              if (
                this.pos.gastro.orderedItems[this.pos.gastro.party.name]
                  .length > 0
              ) {
                return this.pos.gastro.orderedItems[this.pos.gastro.party.name];
              }
            }
          }
        }
      }

      return [];
    },
    openItems() {
      if (this.posType === 'gastro') {
        if (this.pos.gastro.party !== null) {
          if (this.pos.gastro.party.hasOwnProperty('name')) {
            if (
              this.pos.gastro.openItems.hasOwnProperty(
                this.pos.gastro.party.name
              )
            ) {
              if (
                this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0
              ) {
                return this.pos.gastro.openItems[this.pos.gastro.party.name];
              }
            }
          }
        }
      }

      return [];
    },
    totalPrice() {
      if (this.posType === 'gastro') {
        return this.gastroTotalPrice;
      }

      if (this.posType === 'retail') {
        return this.retailTotalPrice;
      }

      if (this.posType === 'wholesaleRetail') {
        return this.wholesaleRetailTotalPrice;
      }

      return 0;
    },
    isInvoiceEmpty() {
      if (this.posType === 'retail')
        if (this.pos.retail.orderedItems.length > 0) return false;

      if (this.posType === 'wholesaleRetail')
        if (this.pos.wholesaleRetail.orderedItems.length > 0) return false;

      if (this.posType === 'gastro')
        if (this.pos.gastro.party !== null)
          if (this.pos.gastro.party.hasOwnProperty('name'))
            if (
              this.pos.gastro.openItems.hasOwnProperty(
                this.pos.gastro.party.name
              )
            )
              if (
                this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0
              )
                return false;

      return true;
    },
    goodsValue() {
      if (this.posType === 'retail') {
        return this.retailGoodsValue;
      }

      if (this.posType === 'wholesaleRetail') {
        return this.wholesaleRetailGoodsValue;
      }

      if (this.posType === 'gastro') {
        return this.gastroGoodsValue;
      }

      return 0;
    },
    invoiceCustomer() {
      if (this.posType === 'retail') return this.pos.retail.customer;

      if (this.posType === 'wholesaleRetail') return this.pos.wholesaleRetail.customer;

      if (this.posType === 'gastro') return this.pos.gastro.customer;

      return null;
    },
  },

  methods: {
    openSavedOrder(uuid) {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.POS.RETAIL.ORDER.GET, {
          orderID: uuid,
        })
        .then((res) => {
          if (res.data.success) {
            if (this.posType === 'retail') {

              this.$store.commit('pos/retail/appendItems', {
                orders: res.data.order.items?.map(item => ({ ...item, isWeighted: false, randomPositionID: randomString.generate(15) })) || [],
              });
              // CHECK IF WE HAVE WEIGHTED ITEMS
              this.$store.commit('pos/retail/openSavedOrder', res.data.order);
            } else {
              this.$store.commit('pos/wholesaleRetail/appendItems', {
                orders: res.data.order.items?.map(item => ({ ...item, isWeighted: false, randomPositionID: randomString.generate(15) })) || [],
              });

              this.$store.commit('pos/wholesaleRetail/openSavedOrder', res.data.order);
            }
            //GET CUSTOMER
            if (res.data.order.customer !== null) {
              this.axios
                .post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
                  customerID: res.data.order.customer.id,
                })
                .then((res2) => {
                  if (res2.data.success) {
                    this.$store
                      .dispatch('pos/setCustomer', {
                        posType: this.posType,
                        customer: res2.data.customer,
                        setCustomerOnly: true,
                      })
                  }
                })
                .catch(() => {
                  Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                  });
                });
            }
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
              color: "error"
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            color: "error"
          });
        });
    },
    checkEventbeeTicket(ean) {
      if (this.$refs.eventbeeScannedTicketDialog) {
        // remove ET$ from ticketId (eg. ET$01e394f0-b1ff-11ed-887a-0a1c9e06025b -> 01e394f0-b1ff-11ed-887a-0a1c9e06025b)
        let ticketId = ean.split('ET$');
        ticketId = ticketId[1];
        this.$refs.eventbeeScannedTicketDialog.checkTicket(ticketId);
      }
    },
    async selectItemEAN(ean, bestBeforeDate, batchNo) {
      if (ean.includes('ET$')) {
        this.checkEventbeeTicket(ean);
        return;
      } else {
        if (this.$refs.eventbeeScannedTicketDialog)
          this.$refs.eventbeeScannedTicketDialog.clean();
      }

      //CHECK IF EAN IS VOUCHER OR GIFTCARD
      if (ean.includes('V$') || ean.includes('GF$')) {
        this.checkVoucher(ean);
        return;
      }

      //CHECK IF EAN IF CUSTOMER CARD
      if (ean.includes('CC$')) {
        this.checkCustomerCard(ean);
        return;
      }

      //CHECK IF EAN IS CUSTOMER ACCOUNT
      if (ean.includes('CA$')) {
        this.checkCustomerAccount(ean);
        return;
      }

      //CHECK IF EAN IS RETOURE
      if (ean.includes('R$')  && this.$store.getters['permissions/checkPermission']('allowVoidItems')) {
        this.checkRetoure(ean);
        return;
      }

      //check if is table scanned
      if (ean.includes('TB$')) {
        await this.checkTable(ean.split('TB$')[1]).then((name) => {
          this.selectTable(name);
        });
        return;
      }
      
      //check if saved order is scanned
      if (ean.includes('SVORD$')) {
        this.openSavedOrder(ean.replace('SVORD$', ''));
        return;
      }
      
      //check if order is scanned
      if (ean.includes('ORD$')) {
          this.signalForPickUp(ean);
        return;
      }

      //check if selfcheckout admin barcode is scanned 
      if (ean.includes('RSPW$') && this.$store.getters['permissions/checkPermission']('access_self_service_pos')) {
          this.checkSelfCheckoutAdmin(ean);
        return;
      }
      
      
      //check if kiosk preorder is scanned
      if (ean.includes('PREORDERS$')) {
          this.openPreOrder(ean);
        return;
      }
      

      //GET ITEM
      let item = await this.$store.dispatch('items/getActiveItemByEAN', ean);

      if (!item) {
        //CHECK IF VMP MODULE 8 IS ACTIVE
        //CHECK IF EAN IS 15 OR 18 CHARS LONG
        //CHECK IF PREFIX IS SET 41 OR 43
        if (
          this.$store.getters['permissions/checkModule'](8) &&
          (ean.length === 13 || ean.length === 15 || ean.length === 18) &&
          (ean.substr(0, 3) == '419' ||
            ean.substr(0, 3) == '439' ||
            ean.substr(0, 3) == '414' ||
            ean.substr(0, 3) == '434')
        ) {
          //PREFIX 419 //REDUCED TAX WITHOUT AGE VERIFICATION
          //PREFIX 439 //REDUCED TAX WITH AGE VERIFICATION
          //PREFIX 414 //FULL TAX WITHOUT AGE VERIFICATION
          //PREFIX 434 //FULL TAX WITH AGE VERIFICATION

          this.checkVMP(ean);
          return;
        } else if (
          this.$store.getters['permissions/checkModule'](27) &&
          parseInt(this.settings.settings.barcodeWithVarPriceWeight) == 1 &&
          ean.length === 13 &&
          (ean.substr(0, 2) == '21' ||
            ean.substr(0, 2) == '22' ||
            ean.substr(0, 2) == '23' ||
            ean.substr(0, 2) == '24' ||
            ean.substr(0, 2) == '25' ||
            ean.substr(0, 2) == '26' ||
            ean.substr(0, 2) == '27' ||
            ean.substr(0, 2) == '28' ||
            ean.substr(0, 2) == '10' ||
            ean.substr(0, 2) == '29')
        ) {
          this.checkBarcodePriceWeight(ean);

          return;
        } else if (
          this.$store.getters['permissions/checkModule'](57) &&
          ean.length === 13 &&
          ean.substr(0, 2) == '77'
        ) {
          /* SPECIAL CASE FOR MYWORLD LOYALITY*/

          this.item = {
            id: ean,
            ean: 'MYWORLD$' + ean,
            allowSetName: 0,
            sellPrice: parseFloat(0.0),
            name: 'MyWorld Loyality ' + ean,
            itemgroupID: parseInt(0),
            taxValue: 0.0,
            variations: [],
            ageVerification: 0
          };

          await this.bookItem(null, null);
          return;
        } else {
          this.playSound('failed');
          return;
        }
      }
      this.playSound('success');
      this.item = item;

      //FIND ITEMGROUP
      if (item.itemgroupID > 0) {
        let itemgroup = await this.$store.dispatch(
          'itemgroups/getItemgroupByID',
          item.itemgroupID
        );

        if (!itemgroup) {
          this.itemgroup = null;
        } else this.itemgroup = itemgroup;
      }
      /**
       * check serialNo
       */

      if (this.item && this.item.hasSerialNo) {
        //scan serial number
        await this.scanSerialNo()
          .then((updatedItem) => {
            this.item = updatedItem;
            this.selectItem(bestBeforeDate, batchNo);
          })
          .catch(() => {
            //cancel item booking
            this.item = null;
            this.itemgroup = null;
          });
      } else await this.selectItem(bestBeforeDate, batchNo);
    },
    /**
     * check if table exists
     * */
    async checkTable(uuid) {
      return new Promise((resolve, reject) => {
        this.axios
          .post(ENDPOINTS.SETTINGS.ROOMPLANER.TABLES.DETAILS, {
            UUID: uuid,
          })
          .then((res) => {
            if (res.data.STATUS === 'SUCCESS' && res.data.table)
              resolve(res.data.table.name);
            else reject();
          })
          .catch(() => reject());
      });
    },
    /**
     * scan item serialNo
     * this method should be used only when this.item has value + has serialNo
     * */
    async scanSerialNo() {
      return new Promise((resolve, reject) => {
        this.waitForSerialNoScannerRejecter = reject;
        this.waitForSerialNoScannerResolver = resolve;
        this.serialNoDialog = true;
      });
    },
    /**
     * the serial number is verified successfully
     * */
    serialNoScanned(serialNo) {
      let tmp = this.item;
      tmp.serialNo = serialNo;
      this.waitForSerialNoScannerResolver({ ...tmp });
      this.serialNoDialog = false;
    },
    serialNoScannerFailed() {
      this.waitForSerialNoScannerRejecter(false);
      this.serialNoDialog = false;
    },
    selectTable(name) {
      let table = {
        name: name,
        parties: [{ name: 'Partei 1' }],
      };
      this.axios
        .post(ENDPOINTS.POS.GASTRO.TABLES.INFO, {
          tableName: name,
        })
        .then((res) => {
          if (res.data.success) {
            table = res.data.table;
          }
          this.$store
            .dispatch('pos/gastro/setTable', {
              posType: 'gastro',
              table: table,
            })
            .then(() => {
              if (this.$route.name === 'pos.gastro')
                Events.$emit('tableScanned');
              else this.$router.replace('/pos/gastro');
            });
        })
        .catch((err) => {});
    },
    selectItemByData(itemgroup, item, weight = null) {
      // FUNCTION FOR BOOKING ITEM WITH GIVEN DATA
      // USED WHEN WE SCAN BARCODE AND API WILL BE CALLED
      this.playSound('success');
      this.item = item;
      this.itemgroup = itemgroup;

      this.weight = weight;

      this.selectItem();
    },
    async selectItem(bestBeforeDate, batchNo) {
      //CHECK IF CURRENT PARTY IS PAYED
      if (this.posType === 'gastro') {
        if (
          this.pos.gastro.party &&
          this.pos.gastro.party &&
          this.pos.gastro.party.hasOwnProperty('is_payed') &&
          this.pos.gastro.party.is_payed
        ) {
          return;
        }
      }

      //VERIFY AGE
      if (
        !this.ageVerified &&
        this.item.ageVerification > 0 &&
        (this.pos.verifiedAge < parseInt(this.item.ageVerification) ||
          parseInt(this.pos.verifiedAge) === 0)
      ) {
        // && (this.pos.ageVerified < parseInt(this.item.ageVerification) || parseInt(this.item.ageVerification) === 0)
        this.minVerifiedAge = this.item.ageVerification;
        this.showAgeVerificationDialog = true;

        return;
      }

      //CHECK FOR ADDITIONAL BARCODES
      if (this.item.addOnBarcodes && this.item.addOnBarcodes.length > 0 && Number(this.settings.settings.disableAddonBarcodeDialog) !== 1) {
        this.showAddBarcodesDialog = true;

        return;
      }

      //CHECK FOR VARIATION
      if (this.item.variations.length > 0) {
        this.showVariationsDialog = true;

        return;
      }

      //CHECK FOR PRICE INPUT
      if (this.item.openPriceInput && this.newPrice === null) {
        setTimeout(()=>{
          this.showPriceInputDialog = true;
        },300)
        return;
      }

      //CHECK IF PAGER NUMBER IS REQUIRED TO BOOK THIS ITEM
      if (
        this.item.pagerNumberReq === 1 &&
        this.$store.state.pos.gastro.pagerNo === null
      ) {
        this.showPagerDialog = true;
        return;
      }

      //CHECK FOR WEIGHT INPUT
      // ALSO CHECKING IF A SCALE SETTING IS NOT DEFINED
      if (
        this.item.openWeightInput &&
        this.weight === null &&
        !this.isScaleEnabled
      ) {
        this.showWeightInputDialog = true;
        return;
      }

      //check if scale module is enabled
      if (this.$store.getters['permissions/checkModule'](61)) {
        // applying scale settings
        if (this.isScaleEnabled && this.weight === null) {
          if (this.item.enableWeighting) {
            if (this.item.enableTara) {
              this.isTara = true;
              this.toggleScale = true;
              return;
            } else {
              this.isTara = false;
              this.toggleScale = true;
              return;
            }
          }
        }
      }

      //CHECK FOR MEALSIZES
      if (this.item.mealSizes.length > 0 && this.mealSize === null) {
        this.showMealsizesDialog = true;

        return;
      }

      //CHECK FOR EXTRAS
      //CHECK IF ITEMGROUP OR ITEM HAS EXTRAS
      let availableExtras = [];
      let extrasFrom = -1; //0 waregroup, 1 item
      if (
        this.posType === 'gastro' &&
        this.itemgroup !== null &&
        this.itemgroup.extras !== null &&
        typeof this.itemgroup.extras !== 'undefined'
      ) {
        if (
          Object.keys(this.itemgroup.extras).length > 0 &&
          this.itemgroup.extras.constructor === Object
        ) {
          availableExtras = this.itemgroup.extras;
          extrasFrom = 0;
        }
      }

      if (
        this.posType === 'gastro' &&
        this.item.extras !== null &&
        typeof this.item.extras !== 'undefined'
      ) {
        if (
          Object.keys(this.item.extras).length > 0 &&
          this.item.extras.constructor === Object
        ) {
          availableExtras = this.item.extras;
          extrasFrom = 1;
        }
      }
      if (
        this.posType === 'gastro' &&
        Object.keys(availableExtras).length > 0 &&
        availableExtras.constructor === Object &&
        this.selectedExtras === null
      ) {
        //CHECK
        let counter = 0;

        //LOOP GARNISHTABS
        for (let tabID in availableExtras) {
          if (!availableExtras.hasOwnProperty(tabID)) {
            return;
          }

          //LOOP GARNISH ITEMS
          for (let garnishID in availableExtras[tabID]) {
            if (!availableExtras[tabID].hasOwnProperty(garnishID)) {
              return;
            }

            let extra = Object.assign(
              {},
              this.extras.extras.find((item) => item.id === parseInt(garnishID))
            );

            if (Object.keys(extra).length < 1) {
              break;
            }

            //LOOP GARNISH SIZES
            for (let garnishSizeID in availableExtras[tabID][garnishID]) {
              if (
                !availableExtras[tabID][garnishID].hasOwnProperty(garnishSizeID)
              ) {
                return;
              }
              if (
                parseInt(availableExtras[tabID][garnishID][garnishSizeID][1]) <
                1
              ) {
                break;
              }
              //CHECK
              if (this.mealSize !== null) {
                if (
                  this.mealSize.id > 0 &&
                  parseInt(garnishSizeID) === this.mealSize.id
                ) {
                  counter++;
                } else if (
                  this.mealSize.id === 0 &&
                  parseInt(garnishSizeID) === 0
                ) {
                  counter++;
                }
              } else {
                if (parseInt(garnishSizeID) === 0) {
                  counter++;
                }
              }
            }
          }
        }

        if (counter > 0) {
          if (extrasFrom === 0) {
            if (this.itemgroup.dontAskForExtras === 0) {
              this.showExtrasDialog = true;
              return;
            }
          } else if (extrasFrom === 1) {
            if (this.item.dontAskForExtras === 0) {
              this.showExtrasDialog = true;
              return;
            }
          }
        }
      }

      // CHECK WARE NAME / BOOKING NUMBER
      if (
        (this.item.bookingNumberReq === 1 && this.bookingNumber === null) ||
        (this.item.allowSetName === 1 && this.warenameOverwrite === null)
      ) {
        setTimeout(()=>{
          this.showWarenameBookingNumberDialog = true;
        },300)
        return;
      }

      //CHECK FOR PREDEFINED COURSE
      if (this.pos.gastro.selectedCourse) {
        this.selectedCourse = Object.assign({}, this.pos.gastro.selectedCourse);
      } else {
        if (
          this.posType === 'gastro' &&
          this.itemgroup !== null &&
          this.itemgroup.predefinedCourseId
        ) {
          let course = this.courses.courses.find(
            (course) => course.id === this.itemgroup.predefinedCourseId
          );
          if (course) {
            this.selectedCourse = Object.assign({}, course);
          }
        } else {
          this.selectedCourse = null;
        }
      }

      await this.bookItem(bestBeforeDate, batchNo);
    },
    async bookItem(bestBeforeDate, batchNo) {
      window.eventBus.$emit('booking', true);
      //CHECK IF CURRENT PARTY IS PAYED
      if (this.posType === 'gastro') {
        if (
          this.pos.gastro.party &&
          this.pos.gastro.party &&
          this.pos.gastro.party.hasOwnProperty('is_payed') &&
          this.pos.gastro.party.is_payed
        ) {
          window.eventBus.$emit('booking', false);
          return;
        }
      }

      //SET OUR NEW VALUES ON ITEM
      let item = Object.assign({}, this.item);
      let itemNetPrice = 0;

      /*  DAILY GOODS  */

      //VERIFY IF DAILY GOODS
      if (this.posType === 'gastro' && Number(this.item.isDailyGoods) > 0) {
        await this.axios
          .post(ENDPOINTS.ERP.DAILYGOODS.GET, {
            editItemID: item.id,
          })
          .then((res) => {
            item.dailyAmount = res.data.dailyAmount;
          });

        if (Number(item.dailyAmount) <= 0) {
          Events.$emit('showSnackbar', {
            message: this.$t('erp.lang_outOfStock'),
            color: 'warning',
          });
          window.eventBus.$emit('booking', false);
          return;
        }
      }
      let discount = 0;
      //CHECK LOCALBEE DISCOUNT

      if (this.posType === 'gastro' && this.pos.gastro.isDeliveryService) {
        if (this.itemgroup) {
          if (this.pos.gastro.delivery) {
            discount = Number(this.itemgroup.deliveryDiscount);
          } else {
            discount = Number(this.itemgroup.takeAwayDiscount);
          }
        }

        if (this.newPrice === null)
          if (this.mealSize !== null) {
            this.newPrice = this.mealSize.price;
          } else {
            if (this.pos.gastro.delivery) {
              this.newPrice =
                this.item.deliveryPrice > 0
                  ? this.item.deliveryPrice
                  : null;
            } else {
              this.newPrice =
                this.item.takeAwayPrice > 0
                  ? this.item.takeAwayPrice
                  : null;
            }
          }
      }

      //CHECK CUSTOMER DISCOUNT

      if (this.invoiceCustomer !== null) {
        // ITEMGROUP DISCOUNT
        if (this.invoiceCustomer.itemgroupDiscount !== null && this.item.itemNotDiscountable!==1) {
          if (this.invoiceCustomer.itemgroupDiscount.hasOwnProperty(item.itemgroupID)) {
            discount = this.invoiceCustomer.itemgroupDiscount[item.itemgroupID] > discount
                ? this.invoiceCustomer.itemgroupDiscount[item.itemgroupID]
                : discount;
          }
        }

        // WARE PRICE
        if (this.invoiceCustomer.group !== null) {
          if (this.invoiceCustomer.group.type === 1 && this.newPrice===null && this.mealSize === null && this.item.priceCannotChange!==1) {
            await this.axios
              .post(ENDPOINTS.POS.CUSTOMERGROUPITEMPRICE, {
                customerGroup: this.invoiceCustomer.group.id,
                itemId: item.id,
              })
              .then((res) => {
                if (res.data.STATUS) this.newPrice = parseFloat(res.data.price);
              });
          }
        }
      }
      /*HAPPY HOUR*/
      if (this.$store.getters['permissions/checkModule'](22) || this.$store.getters['permissions/checkModule'](84)) {
        await this.axios
          .post(ENDPOINTS.POS.HAPPYHOURRULES, {
            itemId: item.id,
            itemGroupId: item.itemgroupID,
          })
          .then((res) => {
            if (res.data.STATUS) {
              if (parseFloat(res.data.price) > 0.0 && this.item.priceCannotChange!==1) {
                // set this flag in case of wholesaleRetail to determine if the new price is from happy hour/special offer or it's a price change
                if(this.posType === 'wholesaleRetail')
                  this.hasHappyHourOrOffer = true;

                this.newPrice = parseFloat(res.data.price) + this.getExtrasTotalPrice();
              }

              if( this.item.itemNotDiscountable!==1 ){
                discount =
                Number(res.data.discount) > discount
                  ? Number(res.data.discount)
                  : discount;
              }
            }
          });
      }

      if (this.mealSize !== null && this.newPrice !== null) {
        item.originalSellPrice = this.mealSize.price;
      } else {
        item.originalSellPrice = item.sellPrice;
      }


      // calculating netprice before tax changes
      itemNetPrice = getNetPrice(item.originalSellPrice, item.taxValue, 2);

      if (
        this.invoiceCustomer !== null &&
        this.invoiceCustomer.group !== null &&
        this.invoiceCustomer.group.setTax >= 0
      ) {
        item.taxValue = this.invoiceCustomer.group.setTax;
      }


      if(this.posType === 'wholesaleRetail'){
        if(this.hasHappyHourOrOffer){
          item.sellPrice = this.newPrice !== null ? this.newPrice : calcGrossPrice(itemNetPrice, item.taxValue, 2);
        }else {
          // if whole sale retail we use the new price as the net price
          item.sellPrice = calcGrossPrice(this.newPrice !== null? this.newPrice : itemNetPrice, item.taxValue, 2);
        }
      }else {
        item.sellPrice = this.newPrice !== null ? this.newPrice : calcGrossPrice(itemNetPrice, item.taxValue, 2);
      }

      item.selectedExtras =
        this.selectedExtras !== null ? this.selectedExtras : [];
      item.mealSizeID = this.mealSize === null ? 0 : this.mealSize.id;

      item.bestBeforeDate = bestBeforeDate;
      item.batchNo = batchNo;
      item.weight = this.weight;
      item.isWeighted = this.isWeighted;
      item.scalePositionId = this.scalePositionId;
      item.depositgroupID = this.item.depositgroupID;
      item.serialNo = this.item.serialNo;
      item.discount = discount;
      item.individualBookingNo = this.bookingNumber;
      item.warenameOverwrite = this.warenameOverwrite;
      item.course = this.selectedCourse;
      item.freeText = null;
      item.isPriceChanged = this.item.openPriceInput;

      //setting a boolean to track weather we re using mobile version or desktop version
      item.isMobile = this.$vuetify.breakpoint.smAndDown;

      if (this.posType === 'gastro') {
        await this.$store.dispatch('pos/gastro/bookItem', item).then(() => {
          this.newPrice = null;
          this.weight = null;
          this.isWeighted = false;
          this.selectedExtras = null;
          this.mealSize = null;
          this.bestBeforeDate = null;
          this.batchNo = null;
          item.serialNo = this.item.serialNo;
          this.minVerifiedAge = 0;
          this.ageVerified = false;
          this.warenameOverwrite = null;
          this.bookingNumber = null;
          this.scalePositionId = null;

          this.$emit('itemBooked');
        });
      } else if (this.posType === 'retail') {
        await this.$store.dispatch('pos/retail/bookItem', item).then(() => {
          this.newPrice = null;
          this.weight = null;
          this.isWeighted = false;
          this.selectedExtras = null;
          this.mealSize = null;
          this.bestBeforeDate = null;
          this.batchNo = null;
          item.serialNo = this.item.serialNo;
          this.minVerifiedAge = 0;
          this.ageVerified = false;
          this.warenameOverwrite = null;
          this.bookingNumber = null;
          this.scalePositionId = null;

          this.$emit('itemBooked');
        });
      } else if (this.posType === 'wholesaleRetail') {
        await this.$store.dispatch('pos/wholesaleRetail/bookItem', item).then(() => {
          this.hasHappyHourOrOffer = false;
          this.newPrice = null;
          this.weight = null;
          this.isWeighted = false;
          this.selectedExtras = null;
          this.mealSize = null;
          this.bestBeforeDate = null;
          this.batchNo = null;
          item.serialNo = this.item.serialNo;
          this.minVerifiedAge = 0;
          this.ageVerified = false;
          this.warenameOverwrite = null;
          this.bookingNumber = null;
          this.scalePositionId = null;

          this.$emit('itemBooked');
        });
      }

      //SOCKET IO
      if (
        this.posType === 'retail' ||
        this.posType === 'wholesaleRetail' ||
        (this.posType === 'gastro' && this.pos.gastro.table.name === 0)
      )
        this.$socket.emit('pos.bookItem', item);

      window.eventBus.$emit('booking', false);
    },
    cancelAddBarcodesDialog() {
      this.showAddBarcodesDialog = false;
    },
    cancelVariationsDialog() {
      this.showVariationsDialog = false;
    },
    cancelPriceInputDialog() {
      this.newPrice = null;

      this.showPriceInputDialog = false;
    },
    cancelWeightInputDialog() {
      this.newPrice = null;
      this.weight = null;

      this.showWeightInputDialog = false;
    },
    cancelScale() {
      this.weight = null;
      this.scalePositionId = null;
      this.isTara = false;

      this.toggleScale = false;
    },
    cancelMealSizeDialog() {
      this.newPrice = null;
      this.weight = null;
      this.mealSize = null;

      this.showMealsizesDialog = false;
    },
    cancelExtrasDialog() {
      this.newPrice = null;
      this.weight = null;
      this.mealSize = null;
      this.selectedExtras = null;

      this.showExtrasDialog = false;
    },
    cancelWarenameBookingnumberDialog() {
      this.newPrice = null;
      this.weight = null;
      this.mealSize = null;
      this.selectedExtras = null;
      this.warenameOverwrite = null;
      this.bookingNumber = null;

      this.showWarenameBookingNumberDialog = false;
    },
    async variationSelected(variation) {
      //FIND ITEMGROUP
      let itemgroup = await this.$store.dispatch(
        'itemgroups/getItemgroupByID',
        variation.itemgroupID
      );

      if (!itemgroup) {
        return;
      }

      this.item = variation;
      this.itemgroup = itemgroup;

      this.$nextTick(() => {
        this.showVariationsDialog = false;

        //RECALL SELECT-ITEM FUNCTION
        this.selectItem();
      });
    },
    async addBarcodeSelected(barcodeItem) {
      //FIND ITEMGROUP
      let itemgroup = await this.$store.dispatch(
        'itemgroups/getItemgroupByID',
          barcodeItem.itemgroupID
      );

      if (!itemgroup) {
        return;
      }

      this.item = barcodeItem;
      this.itemgroup = itemgroup;

      this.$nextTick(() => {
        this.showAddBarcodesDialog = false;

        //RECALL SELECT-ITEM FUNCTION
        this.selectItem();
      });
    },
    priceChanged(newPrice) {
      if (
        this.item.payOutItem === 1 &&
        !this.$store.getters['permissions/checkModule'](46)
      ) {
        // IGNORE SIHOT PMS
        if (parseFloat(newPrice) >= 0) this.newPrice = newPrice * -1;
        else this.newPrice = newPrice;
      } else this.newPrice = newPrice;
      this.showPriceInputDialog = false;
      //RECALL SELECT-ITEM FUNCTION
      this.selectItem();
    },
    pagerUpdated() {
      this.showPagerDialog = false;
      if (
        this.item.pagerNumberReq === 1 &&
        this.$store.state.pos.gastro.pagerNo === null
      ) {
        return;
      }

      //RECALL SELECT-ITEM FUNCTION
      this.selectItem();
    },
    weightAccepted(weight) {
      this.weight = weight;
      this.showWeightInputDialog = false;

      //RECALL SELECT-ITEM FUNCTION
      this.selectItem();
    },
    applyScale({ weight, positionId }) {
      this.weight = weight;
      this.isWeighted = true;
      this.toggleScale = false;
      this.scalePositionId = positionId;

      this.selectItem();
    },
    getExtrasTotalPrice() {
      if (!this.selectedExtras || (Array.isArray(this.selectedExtras) && this.selectedExtras.length < 1))
        return 0;

      let extraPrice = this.selectedExtras.reduce((total, extra) => {
        return total + parseInt(extra.amount) * parseFloat(extra.sellPrice);
      }, 0);

      return extraPrice;
    },
    extrasSelected(extras) {
      this.selectedExtras = extras;
      let extraPrice = 0.0;
      //calculate new extra price
      extras.forEach((extra) => {
        extraPrice += parseInt(extra.amount) * parseFloat(extra.sellPrice);
      });

      if (this.pos.gastro.isDeliveryService) {
        if (this.mealSize !== null) {
          this.newPrice = this.mealSize.price + extraPrice;
        } else if (this.pos.gastro.delivery) {
          this.newPrice =
            this.item.deliveryPrice > 0
              ? this.item.deliveryPrice + extraPrice
              : this.item.sellPrice + extraPrice;
        } else {
          this.newPrice =
            this.item.takeAwayPrice > 0
              ? this.item.takeAwayPrice + extraPrice
              : this.item.sellPrice + extraPrice;
        }
      } else {
        if (this.newPrice !== null) this.newPrice = this.newPrice + extraPrice;
        else this.newPrice = this.item.sellPrice + extraPrice;
      }

      this.showExtrasDialog = false;

      this.selectItem();
    },
    mealSizeSelected(mealSize) {
      this.mealSize = mealSize;
      this.showMealsizesDialog = false;

      this.newPrice = mealSize.price;

      this.selectItem();
    },
    acceptVerifyAge() {
      this.ageVerified = true;
      this.minVerifiedAge = 0;
      this.showAgeVerificationDialog = false;

      //CHECK IF REQUEST WAS FROM VMP ITEM
      if (
        this.item.ean.substr(0, 3) == '439' ||
        this.item.ean.substr(0, 3) == '434'
      ) {
        return this.checkVMP(this.item.ean);
      }

      this.selectItem();
    },
    warenameBookingnumberSubmited(payload) {
      if (this.item.allowSetName === 1) this.warenameOverwrite = payload.name;

      if (this.item.bookingNumberReq === 1)
        this.bookingNumber = payload.bookingNumber;

      this.showWarenameBookingNumberDialog = false;

      this.selectItem();
    },
    async checkVMP(ean) {
      // ---- TEST EAN CODE ----
      // CODE: 4197673405006
      // -----------------------

      //CHECK IF ADDON IS SCANNABLE
      if (ean.length === 13) ean += '00';

      let ageVerification = 0;
      let sellPrice1 = ean.substr(8, 2);
      let sellPrice2 = ean.substr(10, 2);
      let vdzNumber = ean.substr(3, 5);
      let totalPrice = sellPrice1 + '.' + sellPrice2;
      let taxValue;
      let name;

      //CHECK TAX VALUE
      if (parseInt(ean.substr(2, 1)) === 4) {
        taxValue = 19.0;
      } else if (parseInt(ean.substr(2, 1)) === 9) {
        taxValue = 7.0;
      }

      //TODO: CHECK NAME
      name = 'Presseartikel ' + taxValue + '% MwSt.';

      //CHECK IF VMP WAREGROUP ID EXISTS IN SETTINGS
      if (!this.settings.settings.hasOwnProperty('vmp_waregroup')) return;

      //CREATE FAKE ITEM FOR BOOKING
      this.item = {
        id: parseInt(vdzNumber),
        ean: ean,
        sellPrice: parseFloat(totalPrice),
        name: name,
        itemgroupID: parseInt(this.settings.settings.vmp_waregroup),
        taxValue: taxValue,
        variations: [],
        ageVerification: 0,
      };

      //CHECK IF AGE NEEDS TO BE VERIFIED
      //PREFIX 439 //REDUCED TAX WITH AGE VERIFICATION
      //PREFIX 434 //FULL TAX WITH AGE VERIFICATION

      //VERIFY AGE
      if (ean.substr(0, 3) == '439' || ean.substr(0, 3) == '434') {
        if (!this.ageVerified && parseInt(this.pos.verifiedAge) === 0) {
          this.item.ageVerification = 9999;
          this.minVerifiedAge = 9999;
          this.showAgeVerificationDialog = true;

          return;
        }
      }

      await this.bookItem(null, null);
    },
    async checkBarcodePriceWeight(ean) {
      //FIND ITEM

      let eanParsed = ean.substr(0, 7) + '000000';

      let item = await this.$store.dispatch(
        'items/getActiveItemByEAN',
        eanParsed
      );

      if (!item) return;

      let sellPrice = item.sellPrice;
      let weight = null;

      //PRICE BARCODE
      if (item.barcodeWeightPriceMode === 1) {
        sellPrice = parseFloat(ean.substr(7, 3) + '.' + ean.substr(10, 2));
        weight = 1;
      } else if (
        (ean.substr(0, 2) == '22' || ean.substr(0, 2) == '23' || ean.substr(0, 2) == '10') &&
        item.barcodeWeightPriceMode === 0
      ) {
        // EAN     PRICE ?
        // 2200000 00512 0
        //
        // --> 2200000005120

        //PARSE SELLPRICE
        sellPrice = parseFloat(ean.substr(7, 3) + '.' + ean.substr(10, 2));
      }

      //WEIGHT BARCODE
      if (item.barcodeWeightPriceMode === 2) {
        weight = ean.substr(7, 2) + '.' + ean.substr(9, 3);
        weight = isNaN(Number(weight))?1.00:parseFloat(weight);
      } else if (
        (ean.substr(0, 2) == '21' ||
          ean.substr(0, 2) == '27' ||
          ean.substr(0, 2) == '28' ||
          ean.substr(0, 2) == '25' ||
          ean.substr(0, 2) == '26' ||
          ean.substr(0, 2) == '29' ||
          ean.substr(0, 2) == '24') &&
        item.barcodeWeightPriceMode === 0
      ) {
        // EAN     WEIGHT ?
        // 2100000 08123  0
        //
        // --> 2100000081230

        //PARSE WEIGHT
        weight = ean.substr(7, 2) + '.' + ean.substr(9, 3);
      }

      this.weight = parseFloat(weight);

      //SET NEW PRICE + AMOUNT(WEIGHT) ON ITEM
      let bookItem = Object.assign({}, item);
      bookItem.amount = isNaN(Number(weight))?1.00:parseFloat(weight);
      bookItem.sellPrice = sellPrice;

      this.item = bookItem;

      await this.bookItem(null, null);
    },
    checkVoucher(ean) {
      let voucherType;
      let voucherID;
      //CHECK IF THIS VOUCHER ID IS ALREADY BOOKED IN ORDERED
      let orderedVoucher = this.orderedItems.find((orderedItem) => {
        if (orderedItem.ean === ean) {
          if (!orderedItem.isVoid) {
            return true;
          }
        }
      });
      //CHECK IF THIS VOUCHER ID IS ALREADY BOOKED IN OPEN
      let unorderedVoucher = this.openItems.find((openItem) => {
        if (openItem.ean === ean) {
          if (!openItem.isVoid) {
            return true;
          }
        }
      });

      //VOUCHER WITH ID EXISTS ALREADY
      if (orderedVoucher) {
        this.voucherErrorMessage = this.$t('generic.lang_coupon_already_added');
        return;
      }

      if (unorderedVoucher) {
        this.voucherErrorMessage = this.$t('generic.lang_coupon_already_added');
        return;
      }

      //GET VOUCHER TYPE + VOUCHER ID
      if (ean.includes('V$')) {
        voucherType = 1;
        voucherID = ean.split('V$');
      }
      if (ean.includes('GF$')) {
        voucherType = 2;
        voucherID = ean.split('GF$');
      }

      voucherID = voucherID[1];

      //REMOVE SPECIAL CHARS
      voucherID = voucherID.replaceAll('ß', '-');

      if (voucherID === 'newVoucher') return;

      //GET VOUCHER FROM API
      this.axios
        .post(ENDPOINTS.ERP.VOUCHER.GETNEW, {
          voucherID: voucherID,
          voucherType: voucherType,
        })
        .then((res) => {
          if (res.data.success) {
            let voucher = res.data.voucher;

            if (voucherType === 2 && voucher.balance === 0) {
              this.giftCardID = voucher.id;
              this.showGiftCardDialog = true;

              return;
            }

            //CHECK IF TOTAL PRICE > 0
            if (this.goodsValue <= 0) {
              //this.errorSignal.play();

              this.voucherErrorMessage = this.$t(
                'generic.lang_no_items_booked_yet'
              );
              return;
            }

            if (this.totalPrice <= 0) {
              //this.errorSignal.play();

              this.voucherErrorMessage = this.$t(
                'generic.lang_NoMoreVouchersCanBeBooked'
              );
              return;
            }

            if (voucher.balance === 0) {
              this.voucherErrorMessage = this.$t(
                'generic.lang_thereIsNoCreditOnThisVoucher'
              );
              return;
            }

            if (voucher.min_redeem_cart_value && this.goodsValue < voucher.min_redeem_cart_value) {
              this.voucherErrorMessage = this.$t(
                'generic.lang_minCartValueNotReached'
              )+' '+formatCurrency(voucher.min_redeem_cart_value);
              return;
            }

            //CREATE ITEM AS VOUCHER
            let newEanVoucher = '';
            if (voucherType === 1) {
              newEanVoucher = 'V$' + voucher.id;
            } else {
              newEanVoucher = 'GF$' + voucher.id;
            }
            let item = {
              id: newEanVoucher,
              ean: newEanVoucher,
              name:
                (voucherType === 1
                  ? 'Gutschein'
                  : voucherType === 2
                  ? 'Gutscheinkarte'
                  : '') +
                ' ' +
                voucher.id,
              originalSellPrice: voucher.balance * -1,
              sellPrice: voucher.balance * -1,
              weight: null,
              selectedExtras: [],
              mealSizeID: 0,
              depositgroupID: 0,
              discount: 0,
              warenameOverwrite: null,
              individualBookingNo: null
            };

            if(voucher.min_redeem_cart_value){
              item.min_redeem_cart_value = voucher.min_redeem_cart_value;
            }

            //BOOK VOUCHER
            this.$store
              .dispatch('pos/' + this.posType + '/bookItem', item)
              .then(() => {
                if (
                  res.data.voucher.hasOwnProperty('customerId') &&
                  res.data.voucher.customerId > 0 &&
                  !this.pos[this.posType].customer
                ) {
                  this.setCustomer(res.data.voucher.customerId);
                }
                this.$emit('voucherBooked');
              });
          } else {
            if (res.data.code === 'not_found' && voucherType === 2) {
              this.giftCardID = voucherID;
              this.showGiftCardDialog = true;

              return;
            }

            this.voucherErrorMessage = res.data.msg;
          }
        })
        .catch((err) => {
          console.log(err);
          this.voucherErrorMessage = this.$t(
            'generic.lang_couponCouldNotBeRetrieved'
          );
        });
    },
    signalForPickUp(ean){
      let orderUUID = ean.split('ORD$')[1];

      this.axios.post("update/gastro/markOrderPickup/",{
        uuid:orderUUID,
      }).then((res)=>{
        if(res.data.success){
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_ActionSuccessful"),
            color: "success"
          });
        }else{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
            color: "error"
          });
        }
      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
          color: "error"
        });
      })
    },
    checkSelfCheckoutAdmin(admineBarcode){
      if(this.$store.getters["settings/getSettingValue"]("selfServicePosadminBarcode") !== admineBarcode){
        this.playSound("failed");
        return;
      }
      this.playSound("success");
      this.$router.replace({ name: "pos.retail" });
    },
    openPreOrder(ean) {
      let kioskOrderID = ean.split('PREORDERS$')[1]

      this.axios
        .post(ENDPOINTS.POS.GASTRO.PREORDER.GETBYTABLENAME, {
          tableName: kioskOrderID,
        })
        .then((res) => {
          if (res.data.success) {
            this.$store.commit('pos/gastro/setOpenItems', {
              orders: res.data.preorder.items?.map(item => ({ ...item, isWeighted: false, originalSellPrice: Number(item.originalSellPrice), sellPrice: Number(item.sellPrice) })) || [],
              party: {
                name: res.data.preorder.partyName,
              },
            });

            if (res.data.preorder.pick_up_number)
              this.$store.commit('pos/gastro/setPickup', res.data.preorder.pick_up_number);

            if (res.data.preorder.pager_number)
              this.$store.commit('pos/gastro/setPagerNo', res.data.preorder.pager_number);

            this.$store.commit('pos/gastro/setPreorderId', res.data.preorder.tableName);

            this.$store.commit('pos/gastro/setTakeAway', res.data.preorder.isTakeAway? 1: 0);
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        }).finally(() => {

        })
    },
    checkCustomerCard(ean) {
      let customerCardID = ean.split('CC$')[1];

      // CHECK CARD ID LENGTH
      if (customerCardID.length < 2) {
        this.voucherErrorMessage = this.$t(
          'generic.lang_customerCardNrNotValid'
        );
        return;
      }

      //GET CUSTOMER
      this.axios
        .post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
          customerCardID: customerCardID,
        })
        .then((res) => {
          if (res.data.success) {
            let customer = res.data.customer;

            // GET CUSTOMER ALLERGENS
            this.axios
              .post(
                ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS.GETWITHNAMES,
                {
                  customerId: customer.id,
                }
              )
              .then((res) => {
                if (res.data.success) {
                  customer.allergens = res.data.allergens;

                  //ADD CUSTOMER TO INVOICE
                  this.$store
                    .dispatch('pos/setCustomer', {
                      posType: this.posType,
                      customer: customer,
                    })
                    .then(() => {});
                } else {
                  this.voucherErrorMessage = this.$t(
                    'generic.lang_allergensCouldNotBeRetrieved'
                  );
                }
              })
              .catch((err) => {
                this.voucherErrorMessage = err.response.message;
              });
          } else {
            this.voucherErrorMessage = res.data.msg;
          }
        })
        .catch((err) => {
          this.voucherErrorMessage = err.response.message;
        });
    },
    checkCustomerAccount(ean) {
      let customerCardID = ean.split('CA$')[1];

      // CHECK CARD ID LENGTH
      if (customerCardID.length < 2) {
        this.voucherErrorMessage = this.$t(
          'generic.lang_customerCardNrNotValid'
        );
        return;
      }

      //GET CUSTOMER
      this.axios
        .post(ENDPOINTS.POS.CUSTOMERBYACCOUNTCARD, {
          accountUUID: customerCardID,
        })
        .then((res) => {
          if (res.data.success) {
            //GET CUSTOMER
            this.axios
              .post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
                customerID: res.data.customer.id,
              })
              .then((res) => {
                if (res.data.success) {
                  let customer = res.data.customer;

                  // GET CUSTOMER ALLERGENS
                  this.axios
                    .post(
                      ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS
                        .GETWITHNAMES,
                      {
                        customerId: customer.id,
                      }
                    )
                    .then((res) => {
                      if (res.data.success) {
                        customer.allergens = res.data.allergens;

                        //ADD CUSTOMER TO INVOICE
                        this.$store
                          .dispatch('pos/setCustomer', {
                            posType: this.posType,
                            customer: customer,
                          })
                          .then(() => {});
                      } else {
                        this.voucherErrorMessage = this.$t(
                          'generic.lang_allergensCouldNotBeRetrieved'
                        );
                      }
                    })
                    .catch((err) => {
                      this.voucherErrorMessage = err.response.message;
                    });
                } else {
                  this.voucherErrorMessage = res.data.msg;
                }
              })
              .catch((err) => {
                this.voucherErrorMessage = err.response.message;
              });
          } else {
            this.voucherErrorMessage = res.data.msg;
          }
        })
        .catch((err) => {
          this.voucherErrorMessage = err.response.message;
        });
    },
    checkRetoure(ean) {
      if (!this.isInvoiceEmpty) {
        this.voucherErrorMessage = this.$t(
          'generic.lang_completeSaleToCancelInvoice'
        );
        return;
      }

      let invoiceID = ean.split('R$');
      invoiceID = invoiceID[1];

      /*
      //PARSE INVOICE ID
      // REALM    ID      RANDOMINT
      // 01       245     1036
      invoiceID = invoiceID.substring(2, invoiceID.length);
      invoiceID = invoiceID.substring(0, (invoiceID.length - 4));
      */

      //CHECK INVOICE
      this.axios
        .post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.GET, {
          invoiceID: parseInt(invoiceID), // 012451036
        })
        .then((res) => {
          if (res.data.success) {
            //this.successSignal.play();
            this.retoureInvoiceData = res.data.data;
            this.showRetoureDialog = true;
          } else {
            //this.errorSignal.play();

            this.voucherErrorMessage = res.data.msg;
          }
        })
        .catch(() => {
          //this.errorSignal.play();

          this.voucherErrorMessage = this.$t(
            'generic.lang_invoiceCouldNotBeRetrieved'
          );
        });
    },
    playSound(type) {
      if (parseInt(this.settings.settings.playSounds) == 1) {
        //PLAY SOUNDS ON EAN SCAN OR BUTTON CLICK
        //TYPES:
        // SUCCESS OR FAILED
        if (type == 'success') {
          let audio = new Audio(require('@/sounds/beep-10.wav'));
          audio.play();
        } else if (type === 'failed') {
          let audio = new Audio(require('@/sounds/error.wav'));
          audio.play();
        }
      }
    },
    setItemPrices(state, data) {
      if (data) {
        state.items.forEach((item) => {
          if (!item.mealsize) item.sellPrice = item.deliverPrice; //TODO OMARIZEM

          item.discount = item.itemGroup
            ? item.itemGroup['delivery discount']
            : 0;
        });
      } else {
        state.items.forEach((item) => {
          if (!item.mealsize) item.sellPrice = item.takeAwayPrice;

          item.discount = item.itemGroup
            ? item.itemGroup['takeaway discount']
            : 0;
        });
      }
    },
    async setCustomer(id) {
      // GET CUSTOMER
      await this.axios
        .post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
          customerID: id,
        })
        .then(async (res) => {
          if (res.data.success) {
            let customer = res.data.customer;
            // GET CUSTOMER ALLERGENS
            await this.axios
              .post(
                ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS.GETWITHNAMES,
                {
                  customerId: id ? id : this.selectedCustomer.id,
                }
              )
              .then(async (res) => {
                if (res.data.success) {
                  customer.allergens = res.data.allergens;
                  //ADD CUSTOMER TO INVOICE
                  await this.$store.dispatch('pos/setCustomer', {
                    posType: this.posType,
                    customer: customer,
                  });
                }
              });
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {});
    },
  },
};
</script>
