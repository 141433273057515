<template>
  <v-dialog max-width="400" persistent scrollable :value="dialog">
    <v-card :loading="loading" >
      <v-card-text class="text-center my-4">
        <h3>{{errorMessage}}</h3>
      </v-card-text>

      <v-divider class="ma-0" v-if="errorMessage!==$t('generic.lang_Weighting')"/>
      <v-card-actions v-if="errorMessage!==$t('generic.lang_Weighting')">
        <v-btn color="error" :disabled="loading" :loading="loading" @click="$emit('cancelScale')">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn color="success" :disabled="loading" :loading="loading" @click="weight">
          {{ $t('generic.lang_retry') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {Events} from "../../../plugins/events";
import {mapGetters} from "vuex";
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Hex from 'crypto-js/enc-hex';

export default {
  name: "ItemScaleComponent",
  props: {
    item: {
      type: Object,
      default: ()=>{},
    },
    toggleScale:Boolean,
    isTara:Boolean,
    newPrice: {
      type:Number,
      default:null,
    }
  },
  data(){
    return{
      errorMessage:'',
      dialog:false,
      loading:false,
    }
  },
  watch:{
    toggleScale(val){
      if(val){
        this.dialog = true;
        this.weight();
      }else{
        this.dialog = false;
      }
    }
  },
  computed:{
    ...mapGetters({
      host:'scale/host',
      port:'scale/port',
    }),
  },
  methods:{
    weight(){
      this.loading = true;
      this.errorMessage = this.$t('generic.lang_Weighting');
      if(this.isTara){
        let timer = setTimeout(async ()=> {
          await this.getWeightWithoutTara()
          clearTimeout(timer);
        },1000);
      }else{
        let timer = setTimeout(async ()=> {
          await this.getWeight()
          clearTimeout(timer);
        },1000);
      }
    },
    async getWeight() {

      //CREATE NEW AXIOS INSTANCE
      let axios = this.axios.create();
      //REMOVE 3POS HEADERS
      delete axios.defaults.headers.common['3POSAPITOKEN'];
      delete axios.defaults.headers.common['3POSBS'];
      delete axios.defaults.headers.common['3POSBSID'];

      let price = 0;

      if(this.item && this.item.sellPrice){
        price = Number(this.item.sellPrice.toFixed(2).replace('.',''));
      }

      if(this.newPrice!==null && this.newPrice!==undefined){
        price = Number(this.newPrice.toFixed(2).replace('.',''))
      }

      const saltedSalt = "$2b$10$smf9BxDbM5dpkflP5fcITu";
      let hash = hmacSHA512(price.toString(),saltedSalt);
      const value = Hex.stringify(hash);

      axios.post('http://'+this.host+':'+this.port+'/weight-price', {
        price:price,
        hash: value
      }).then((res) => {
        if (res.data.status) {

          //CHECK HASH
          let hashReturn = hmacSHA512(res.data.msg.weight.toString(),saltedSalt);
          const valueReturn = Hex.stringify(hashReturn);
          //CHECK HASH
          if(res.data.msg.hash === valueReturn) {
            this.dialog = false;
            this.$emit('applyScale', {weight:res.data.msg.weight,positionId:res.data.msg.positionId})
          }else{
            this.errorMessage = this.$t('generic.lang_anErrorOccurredPlsRepeatWeighing');
          }
        } else {
          this.errorMessage=res.data.msg;
        }
      }).catch(err=>{
        this.errorMessage = err.message;
      }).finally(() => {
        this.loading = false;
      });
    },
    async getWeightWithoutTara() {


      //CREATE NEW AXIOS INSTANCE
      let axios = this.axios.create();
      //REMOVE 3POS HEADERS
      delete axios.defaults.headers.common['3POSAPITOKEN'];
      delete axios.defaults.headers.common['3POSBS'];
      delete axios.defaults.headers.common['3POSBSID'];

      let price = 0;
      let tara = 0;

      if(this.item && this.item.sellPrice){
        price = Number(this.item.sellPrice.toFixed(2).replace('.',''));
        tara = Number(this.item.tara);
      }

      if(this.newPrice!==null && this.newPrice!==undefined){
        price = Number(this.newPrice.toFixed(2).replace('.',''))
      }

      const saltedSalt = "$2b$10$smf9BxDbM5dpkflP5fcITu";
      let hash = hmacSHA512(price.toString()+""+tara.toString(),saltedSalt);
      const value = Hex.stringify(hash);

      await axios.post('http://'+this.host+':'+this.port+'/weight-price-tara', {
        price:price,
        tara:tara,
        hash:value
      }).then((res) => {
        if (res.data.status) {

          //CHECK HASH
          let hashReturn = hmacSHA512(res.data.msg.weight.toString(),saltedSalt);
          const valueReturn = Hex.stringify(hashReturn);
          //CHECK HASH
          if(res.data.msg.hash === valueReturn){
            this.dialog = false;
            this.$emit('applyScale',{weight:res.data.msg.weight,positionId:res.data.msg.positionId})
          }else{
            this.errorMessage = this.$t('generic.lang_anErrorOccurredPlsRepeatWeighing');
          }

        } else {
          this.errorMessage=res.data.msg;
        }
      }).catch(err=>{
        this.errorMessage = err.message;
      }).finally(() => {
        this.loading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>