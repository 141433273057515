<template>
    <Tables/>
</template>

<script>
    import Tables from "../../components/pos/Tables";

    export default {
        name: "GastroTables",

        components: {
            Tables
        }
    }
</script>