<template>
  <v-dialog v-model="showDialog" max-width="700px" persistent scrollable>
    <v-card :loading="loading">
      <v-toolbar dense flat>
        <v-toolbar-title class="text-capitalize">{{ $t('erp.lang_additionalBarcodes') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="cancelDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="my-1"/>
      <v-card-text style="padding:0px !important;">
        <v-container>
          <v-row>
            <v-col class="pb-0" cols="12">
              <v-text-field v-model="search" :label="this.$t('datatables.lang_search')" dense outlined/>
            </v-col>
            <v-col cols="12" sm="6" md="4" v-for="(item,index) in filteredBarcodes" :key="index" :ref="item.id+'_item'">
              <v-card
                  v-if="item.productImage && displayImage && item.productImage.length > 7"
                  class="pa-0" @click="selectAddBarcode(item)"
                  elevation="1" height="120" width="100%" :color="backgroundColor" v-ripple="{class: 'primary--text'}">
                <v-img
                    cover
                    :src="imageURL(item)"
                    height="100%"
                >
                  <v-card-text class="pa-1 fill-height text-center"
                               :style="{color: (checkColorLightOrDark(item.productImage) === 'light' ? 'black' : 'white'),backdropFilter: 'brightness(0.8)'}">
                    <p class="itemName ma-0" :style="[itemNameStyle]">
          <span v-if="showItemPLU" style="font-weight: bold;">{{
              item.ean
            }}<br></span>
                      {{
                        (itemNameLength > 0) ? item.name.substring(0, itemNameLength) : item.name
                      }}
                    </p>
                    <p class="itemPrice ma-0" :style="[itemPriceStyle]">{{ item.sellPrice | currency }}</p>
                  </v-card-text>
                </v-img>
              </v-card>

              <v-card v-else class="pa-0" @click="selectAddBarcode(item)" elevation="1" height="120" width="100%"
                      :color="backgroundColor"
                      v-ripple="{class: 'primary--text'}">
                <v-card-text class="pa-1 fill-height text-center"
                             :style="{color: (checkColorLightOrDark(backgroundColor) === 'light' ? 'black' : 'white')}">
                  <p class="itemName ma-0" :style="[itemNameStyle]">
                    <span v-if="showItemPLU" style="font-weight: bold;">{{ item.ean }}<br></span>
                    {{
                      (itemNameLength > 0) ? item.name.substring(0, itemNameLength) : item.name
                    }}
                  </p>
                  <p class="itemPrice mb-0" :style="[itemPriceStyle]">{{ item.sellPrice | currency }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="cancelDialog">{{ $t('generic.lang_cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "../../../config";
import {checkColorLightOrDark} from "../../../plugins/checkColorLightOrDark";

export default {
  name: "ItemAdditionalBarcodesDialog",

  props: {
    showDialog: Boolean,
    item: Object
  },

  data() {
    return {
      storageCount: {},
      additionalBarcodes: [],
      loading: false,
      search: null,
    }
  },

  computed: {
    imageURL() {
      return (item)=>{
        // return "https://img.3pos.de/unsafe/filters:quality(5)/billsys_" + this.$store.state.api.auth.posID + "/original/" + item.productImage;
        return "https://img.3pos.de/unsafe/"+ this.itemWidth(item) + "x" + this.itemHeight(item) +"/billsys_" + this.$store.state.api.auth.posID + "/original/" + item.productImage;
      }
    },
    itemNameStyle() {
      let size = (Number(this.$store.getters['settings/getSettingValue']('layoutSettings_fontSize') > 0)) ? Number(this.$store.getters['settings/getSettingValue']('layoutSettings_fontSize')) : 12;

      return {
        fontSize: size + 'px !important',
        lineHeight: (size + size * 0.2) + 'px !important',
        height: '100%',
      };
    },
    itemWidth(){
      return (item)=>{
        return this.$refs[item.id+'_item']?this.$refs[item.id+'_item'][0]?.clientWidth:160;
      }
    },
    itemHeight(){
      return (item)=>{
        return this.$refs[item.id+'_item']?this.$refs[item.id+'_item'][0]?.clientHeight:160;
      }
    },
    itemPriceStyle() {
      let size =((Number(this.$store.getters['settings/getSettingValue']('layoutSettings_fontSize') > 0)) ? Number(this.$store.getters['settings/getSettingValue']('layoutSettings_fontSize')) : 12) - 2;

      return {
        fontSize: size + 'px !important',
        lineHeight: (size + size * 0.2) + 'px !important',
      };
    },
    displayImage() {
      return Number(this.$store.getters['settings/getSettingValue']('disableImageDisplay')) !== 1
    },
    itemNameLength() {
      return Number(this.$store.getters['settings/getSettingValue']('layoutSettings_itemNameLength'));
    },
    showItemPLU() {
      return Number(this.$store.getters['settings/getSettingValue']('layoutSettings_showItemPLU')) > 0;
    },
    backgroundColor() {
      if (this.item.backgroundColor === "") {
        return "#FFFFFF";
      }
      return this.item.backgroundColor;
    },
    filteredBarcodes() {
      if (this.search)
        return this.additionalBarcodes.filter((elt) => elt.name.toLowerCase().includes(this.search.toLowerCase()) || elt.ean.toLowerCase().includes(this.search.toLowerCase()))

      return this.additionalBarcodes;
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.search = null;
        this.loadAddBarcodes()
      } else {
        this.storageCount = {};
      }
    }
  },

  methods: {
    checkColorLightOrDark,
    loadAddBarcodes(){
      this.loading = true;
      let baseItem = Object.assign({},this.item)
      baseItem.addOnBarcodes = [];
      baseItem.variations = [];
      this.additionalBarcodes = [baseItem];

      if (this.item === null || !Array.isArray(this.item.addOnBarcodes) || this.item.addOnBarcodes.length < 1)
        return this.additionalBarcodes;

      this.item.addOnBarcodes.forEach((barcodeID) => {
        this.$store.dispatch("items/getItemByID", barcodeID).then(barcode=>{
          if (barcode)
            this.additionalBarcodes.push(barcode);
        })
      })
      this.loading = false;
    },
    cancelDialog() {
      this.$emit("cancelDialog");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    selectAddBarcode(barcodeItem) {
      this.$emit("selectAddBarcode", barcodeItem);
    },
  }
}
</script>
<style>

.itemPrice {
  font-family: Anton;
  font-weight: normal;
  letter-spacing: 2px;
  position: absolute !important;
  bottom: 2px;
  left: 0;
  width: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.itemName {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
</style>