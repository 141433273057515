<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        color="#424242"
        dark
        dense
        flat
      >
        <v-toolbar-title>{{ $t('erp.lang_define_price') }}</v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          @click="cancelDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-layout>
          <v-flex md12>
            <v-text-field
              v-if="this.$vuetify.breakpoint.smAndDown"
              v-model="newPrice"
              :label="$t('erp.lang_sellPrice')"
              outlined
              prepend-icon="euro"
              style="margin-top: 30px"
              @keyup.enter="changePrice"
              :suffix="$store.getters['settings/currencyData']('symbol')"
              value="0.00"
            />

            <v-text-field
              v-else
              v-model="newPrice"
              :label="$t('erp.lang_sellPrice')"
              outlined
              prepend-icon="euro"
              autofocus
              @keyup.enter="changePrice"
              style="margin-top: 30px"
              :suffix="$store.getters['settings/currencyData']('symbol')"
              value="0.00"
            />

            <NumpadOnly
              ref="numpad"
              @resetField="numpadResetField"
              @updateValue="numpadUpdateValue"
            ></NumpadOnly>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="cancelDialog"
          >{{ $t('generic.lang_cancel') }}</v-btn
        >
        <v-spacer />
        <v-btn
          color="success"
          @click="changePrice"
          >{{ $t('erp.lang_define_price') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumpadOnly from '../NumpadOnly';

export default {
  name: 'ItemPriceInputDialog',

  components: {
    NumpadOnly,
  },

  props: {
    showDialog: Boolean,
    item: Object,
  },

  data() {
    return {
      newPrice: 0,
    };
  },
  destroyed() {
    this.$root.$emit('barcodeScanner.unpause')
  },
  watch: {
    showDialog(val) {
      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (!val) {
        this.$refs.numpad.resetField();
        this.newPrice = 0;
        this.$root.$emit('barcodeScanner.unpause')
      }else{
        this.$root.$emit('barcodeScanner.pause')
      }
    },
  },

  methods: {
    cancelDialog() {
      this.$emit('cancelDialog');
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    changePrice() {
      if (this.newPrice !== 0) {
        if (this.newPrice.toString().includes(',')) {
          this.newPrice = this.newPrice.toString().replace(',', '.');
        }
        this.newPrice = parseFloat(
          this.newPrice.toString().replace(/[^0-9.]/g, '')
        );

        if (Number.isNaN(this.newPrice)) {
          this.newPrice = 0.0;
        }
      }
      this.$emit('changePrice', parseFloat(this.newPrice));
      this.newPrice = 0;
    },
    numpadUpdateValue(value) {
      value = value.replace(',', '').replace('.', '');
      if (value && value.length > 0) {
        let tmp = value.replace(',', '').replace('.', '');
        for (let i = 0; i <= 3 - tmp.length; i++) tmp = '0' + tmp;

        tmp = [
          tmp.substring(0, tmp.length - 2),
          ',',
          tmp.substring(tmp.length - 2, tmp.length),
        ]
          .join('')
          .replace('.', '')
          .replace(',', '.');

        // CHECK IF PAYOUT ITEM
        if (this.item.payOutItem === 1
           // && !this.$store.getters['permissions/checkModule'](46)
            )
        {
          this.newPrice = tmp * -1;
        }
        else {
          this.newPrice = tmp;
        }
      } else {
        this.newPrice = value;
      }
    },
    numpadResetField() {
      this.newPrice = '';
    },
  },
};
</script>
