<template>
  <v-dialog
    v-model="visible"
    max-width="500px"
    @click:outside="clean"
  >
    <v-card>
      <v-card-title class="card-header">
        <span class="headline">
          {{ this.dialogTitle }}
        </span>
      </v-card-title>

      <v-card-text class="text-center py-3">
        <v-alert
          text
          prominent
          dense
          type="error"
          v-if="!this.isValidTicket"
        >
          {{ this.$t('eventbee.lang_invalid_ticket') }}
        </v-alert>
        <v-alert
          text
          prominent
          dense
          type="success"
          v-else
        >
          {{ this.$t('eventbee.lang_valid_ticket') }}
        </v-alert>

        <v-btn
          color="primary"
          class="mx-auto"
          depressed
          large
          block
          @click="clean"
        >
          {{ this.$t('generic.lang_close') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';

export default {
  name: 'EventbeeScannedTicketDialog',
  data() {
    return {
      visible: false,
      /**
       * {
          "ticketUUID": "UUID",
          "status": "BOOL TRUE/FALSE",
          "priceSegment": "NameOfPriceSegment",
          "personalized": "BOOL TRUE/FALSE",
          "personalizedName": "Name of buyer"
        }
       */
      ticket: {},
      timer: null,
    };
  },
  watch: {
    visible(value) {
      if (!value) {
        this.clean();
      }
    },
  },
  computed: {
    isValidTicket() {
      return Object.keys(this.ticket).length > 0 && this.ticket.status;
    },
    dialogTitle() {
      if (this.isValidTicket && this.ticket?.personalized) {
        return this.ticket.personalizedName;
      } else {
        return this.$t('eventbee.lang_eventbee_ticket');
      }
    },
  },
  methods: {
    clean() {
      this.ticket = {};
      this.visible = false;
      clearInterval(this.timer);
    },
    checkTicket(ticketId) {
      this.axios
        .post(ENDPOINTS.EVENTBEE.POS.TICKETS.CHECK_VALIDITY, {
          uuid: ticketId,
        })
        .then((response) => {
          this.ticket = response.data;
          this.visible = true;

          this.timer = setInterval(() => {
            this.clean();
          }, 10000);
        })
        .catch((error) => {
          Events.$emit('show-snackbar', {
            text: error.response.data.message,
            color: 'error',
          });
        });
    },
  },
};
</script>
