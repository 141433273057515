<template>
  <v-dialog v-model="dialogOpen" persistent scrollable max-width="600px">
    <v-card>
      <v-toolbar elevation="0" dense flat>
        <v-spacer/>
        <v-toolbar-title>
          <div class="menu-header-content">
            <h5 class="menu-header-title">{{ $t('erp.lang_mealVariation') }}</h5>
          </div>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn class="ma-0" fab depressed color="transparent" small @click="cancelDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text style="overflow-y: auto;max-height: 400px;">
        <div class="grid-menu grid-menu-2col overflow-hidden ">
          <div class="no-gutters row">
            <div class="col-sm-6" v-for="mealSize in mealSizes" :key="mealSize.id">
              <button :key="mealSize.id" @click="bookMealSize(mealSize)" v-bind:disabled="loading"
                      class="btn-icon-vertical btn-transition btn btn-outline-primary">
                <p class="text-center" style="font-weight: bold; font-size: 20px; margin-top: 10px;">
                  {{ mealSize.sizeName }}</p>
                <p class="text-center"
                   style="font-size: 15px; margin-top: -15px;font-family: ANTON !important;letter-spacing: 1px !important;text-align: center !important;-moz-user-select: none;-khtml-user-select: none;-webkit-user-select: none;">
                  {{ mealSize.price | currency }}</p>
              </button>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "ItemMealSizesDialog",

  props: {
    showDialog: Boolean,
    item: Object,
  },

  data() {
    return {
      dialogOpen: false,
      loading: false,
      mealSizes:[],
    }
  },

  mounted() {
    this.dialogOpen = this.showDialog;
  },

  watch: {
    showDialog(val) {
      this.dialogOpen = val;
    },
    dialogOpen(val){
      if (val) {
        this.loading = false;
        this.initMealSizes();
      }
    }
  },
  computed: {
    ...mapState([
        'mealsizes'
    ]),
  },
  methods: {
    initMealSizes(){
      this.mealSizes=[]
      this.item.mealSizes.forEach(mealSize=>{
        let tmp = this.mealsizes.mealSizes.find(searchM=>searchM.id===mealSize.id)
        if(tmp){
          this.mealSizes.push({
            id:mealSize.id,
            price:mealSize.price,
            sizeName:mealSize.sizeName,
            sorting:tmp.sorting,
          })
        }
      })
      this.mealSizes = this.mealSizes.sort((a,b)=>a.sorting-b.sorting);
    },
    cancelDialog() {
      this.loading = false;
      this.$emit("cancelDialog")

    },
    closeDialog() {
      //RESET DATA
      this.loading = false;
      this.$emit("closeDialog")

    },
    bookMealSize(mealSize) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$emit("selectMealsize", mealSize)
    }
  }
}
</script>
<style scoped>
</style>