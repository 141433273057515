<template>
  <vue-html2pdf

      :filename="pdfFIleName"
      :paginate-elements-by-height="1400"
      :pdf-orientation="pdfOrientation"
      :pdf-quality="2"
      :preview-modal="true"
      :show-layout="false"
      pdf-content-width="100%"
      pdf-format="a4"
      ref="html2Pdf"
  >
    <section style="width: 100% !important;min-width: 100% !important;"
             :id="pdfOrientation === 'portrait'? 'pdfcontainer' : 'pdfcontainer_land'"
             slot="pdf-content">
      <section style="width: 100% !important; " class="pdf-item">
        <h4 id="pdf-title" style="padding-top: 8px !important; padding-left: 8px !important">
          {{ pdfTitle || pdfFIleName }}
        </h4>

        <v-container style="width: 100% !important;margin: 0" fluid>

          <v-simple-table style="width: 100% !important" dense id="pdftable">
            <template v-slot:default>
              <thead style="background-color: lightgrey; padding-top: 3px !important; padding-bottom: 3px !important">
              <tr>
                <th>
                  {{$t("generic.lang_table")}}
                </th>
                <th>
                  {{$t("generic.lang_from_to")}}
                </th>
                <th>
                  {{ $t("generic.lang_customer") }}
                </th>

                <th class="text-capitalize">
                  {{ $t("generic.lang_amountOfGuests") }}
                </th>
                <th class="text-capitalize">
                  {{ $t("generic.lang_confirmed") }}
                </th>

                <th>
                  {{$t("erp.lang_item")}}
                </th>

                <th>
                  {{$t("generic.lang_total")}}
                </th>

                <th>
                  {{ $t("generic.lang_notes") }}
                </th>
              </tr>
              </thead>
              <tbody v-if="Array.isArray(reservations) && reservations.length > 0">
              <tr v-for="reservation in reservations" :key="reservation.uuid">
                <td>
                  <ul>
                    <li v-for="table in reservation.tables" :key="table.uuid">
                      {{ table.name }}
                    </li>
                  </ul>
                </td>

                <td>
                  {{ reservation.time }} - {{ reservation.end_at }}
                </td>

                <td>
                  {{ reservation.name }}
                </td>

                <td>
                  {{ reservation.person }}
                </td>

                <td>
                  {{ reservation.confirmed }}
                </td>

                <td>
                  <ul>
                    <li v-for="item in reservation.items" :key="item.id">
                      x{{ item.quantity }} - {{ item.name }}
                    </li>
                  </ul>
                </td>
                <td>
                  {{ reservation.total | currency }}
                </td>
                <td>
                  {{ reservation.note }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </section>

    </section>
  </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: "ReservationPdfComponent",
  props: {
    reservations: {
      type: Array,
      required: true
    },
    date:{
      type:Date,
      required: true
    }
  },
  data(){
    return{
      pdfOrientation: "landscape",
    }
  },
  components: {
    'vue-html2pdf': VueHtml2pdf
  },
  computed: {
    pdfTitle() {
      return "Reservierungen: " + this.date.toLocaleDateString('de-DE');
    },
    pdfFIleName() {
      return "reservations " + this.date.toLocaleDateString()
    },
  },
  methods: {
    generatePDF() {
      this.$refs.html2Pdf.generatePdf()
    },
  }
}
</script>

<style scoped>

</style>
