<template>
  <div id="cont" ref="cont" :style="{height: (this.$vuetify.breakpoint.height-112)+'px'}"
       class="bg-default cont responsiveTable">
        <span v-if="this.$store.getters['permissions/checkPermission']('roomPlanCreateEdit')" :class="'editor-btn'"
              @click="toggleEdit()">
            <font-awesome-icon v-if="editor.loading" :icon="['fal','spinner']" pulse/>
            <font-awesome-icon v-else :icon="['fas','pencil']"/></span>
    <span v-if="!editor.active&&this.$store.getters['permissions/checkPermission']('roomPlanCreateEdit')" :class="'info-btn'"
          @click="infoMod=!infoMod">
            <font-awesome-icon v-if="infoMod" :icon="['fal','calendar-alt']" />
            <font-awesome-icon v-else :icon="['fas','info']"/></span>
    <div v-if="editor.active" class='editor-option' @click="edit($event)" @mouseenter="edit($event)"
         @mouseleave="edit($event)">
            <span class='btn new-table' @click="showEditName(0)">
                <font-awesome-icon :icon="['fas','plus']"/>
            </span>
      <span v-if="modify" :class="{enabled:(getActive!==null),disabled:(getActive===null)}" class='btn rename '
            @click="showEditName(1)">
                <font-awesome-icon :icon="['fas','font']"/>
            </span>
      <span v-if="modify" :class="{enabled:(getActive!==null),disabled:(getActive===null)}" class='btn clone '
            @click="clone()">
                <font-awesome-icon :icon="['fas','clone']"/>
            </span>
      <span :class="{enabled:(getActive!==null),disabled:(getActive===null)}" class='btn shape '>
                <span class='button-option ' @click="changeShape()"><font-awesome-icon :icon="['fas',getShape]"/></span>
            </span>
      <span v-if="(getActive===null)" class='btn color' @click="editor.paint=!editor.paint">
                <font-awesome-icon :icon="['fas','tint']"/>
                <div v-if="editor.paint&&editor.active" class="button-option main-card mb-2 card color-palette">
                    <div class="card-body">
                        <div v-for="(color,index) in palette" :key="index">
                            <div :style="{backgroundColor:color}" class=" swatch-holder swatch-holder-lg "
                                 @click="changeColor(color,index)"></div>
                        </div>
                    </div>
                </div>
            </span>
      <span :class="{enabled:(getActive!==null),disabled:(getActive===null)}" class='btn seats '
            @click="showConfig()">
                <font-awesome-icon :icon="['fas','cogs']"/>
            </span>
      <span v-if="modify" :class="{enabled:!(getActive===null),disabled:(getActive===null)}" class='btn trash '
            @click="deleteTable">
                <font-awesome-icon :icon="['fas','trash']"/>
            </span>
    </div>
    <div id="floor" ref="floor" :style="{backgroundColor:editor.background,background:`url(${bgImage})`}" class="floor border-top"
         @click.self="disableEdit()">
        <vue-drag-resize v-for="(item,index) in roomTables"
                         v-show="showTable(item,index)"
                         :key="item.name+index"
                         :class="{drag:item.active}"
                         :h="item.height||20" :isActive="item.active"
                         :isDraggable="item.draggable" :isResizable="item.resizable" :minh="50"
                         :minw="50"
                         :parentLimitation="true" :preventActiveBehavior="!editor.active" :w="item.width||20" :x="item.left"
                         :y="item.top"
                         v-on:activated="setActive(index)"
                         v-on:deactivated="setInactive(index)"
                         v-on:dragging="calculatePos($event,index)"
                         v-on:resizing="resize($event,index)"
        >
          <span v-if="infoMod&&reservationTables&&reservationTables.hasOwnProperty(item.name)&&reservationTables[item.name].length>0" class="badge badge-pill badge-warning position-absolute" style="z-index:1;">{{reservationTables[item.name].length}}</span>
          <v-menu bottom :close-on-click="false" :close-on-content-click="true">
          <template v-slot:activator="{on}">
            <div :class="['room-table',item.type]" :style="{backgroundColor:getStatusColor(index,item.name)}"
                 @click="showPartiesCount(item,index)" :title="item.uuid" v-on="(infoMod)?on:''">
              <v-row class="w-100" justify="center" no-gutters>
              <span v-if="findTable(index)!==-1" class="table-total px-1">
                {{ getTotal(index)|currency }}
              </span>
              </v-row>
              <v-row align="center" class="w-100" justify="space-between" no-gutters>
                <v-col align-self="center" class="px-1" cols="2">
              <span v-if="findTable(index)!==-1" class="table-timer">
                <v-progress-circular v-bind:class="parseInt(getColor(index)[1]) === 100? 'blink_me' : ''" :key="loading"
                                     :color="getColor(index)[0]"
                                     :value="getColor(index)[1]"
                                     rotate="275"
                                     size="20"
                                     width="10"
                />
              </span>
                </v-col>
                <v-col align="center" align-self="center" cols="8">
              <span class="table-name" :style="{color: (checkColorLightOrDark(getStatusColor(index,item.name)) === 'light' ? 'black' : 'white'),fontSize:tableNameFontSize+'px'}">
                  {{ item.name }}
              </span>
                </v-col>
                <v-col align="end" align-self="center" class="px-1" cols="2">
              <span v-if="lockTable(index)" class="table-state" :style="{color: (checkColorLightOrDark(getStatusColor(index,item.name)) === 'light' ? 'black' : 'white')}">
                  <font-awesome-icon style="font-size: 16px" :icon="['fal','lock-alt']"/>
              </span>
                </v-col>
              </v-row>
              <v-row align="end" class="w-100" justify="center" no-gutters>
            <span class="table-client px-1" >
                {{ item.maxParties }}

            </span>
                <template v-if="findTable(index)!==-1">
                  <img class="localbee-img" v-if="savedTables[findTable(index)].source!=='pos'" :src="bee" width="20" height="30"/>
                </template>
              </v-row>
              <span :style="{height:getGoalState(index)+'%'}" class="room-table-filter"></span>
            </div>
          </template>
            <v-simple-table v-if="infoMod&&reservationTables&&reservationTables.hasOwnProperty(item.name)" dense>
              <thead>
              <tr class="text-center py-0 border-bottom"> <td colspan="4" class="py-0">{{item.name}}</td> </tr>
              <tr class="border-bottom">
                <td>{{ $t('generic.lang_customer') }}</td>
                <td>{{ $t('generic.lang_from') }}</td>
                <td>{{ $t('generic.lang_to') }}</td>
                <td>{{ $t('generic.lang_persons') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data,index) in reservationTables[item.name]" :key="index" :class="(index%2===0)?'bg-grey':''">
                <td>{{ data.customer }}</td>
                <td>{{ data.startsAt }}</td>
                <td>{{ data.endsAt }}</td>
                <td>{{ data.persons }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-menu>
        </vue-drag-resize>
    </div>
    <v-dialog v-model="dialog.name" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ dialog.edit ? $t('settings.lang_editTableName') : $t('settings.lang_addTable') }}
        </v-card-title>

        <v-card-text>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field :loading="loadingEditName" v-model="dialog.tableName" :error="dialog.tableNameValide"
                            :error-messages="dialog.tableNameMessage" @keypress="preventSpecialChars"
                            :label="$t('generic.lang_table')" type="text"/>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn color="error" text @click="dialog.name = false">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <v-btn color="success" :disabled="!dialog.tableName" @click="editName()">{{
              $t('generic.lang_create')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.config" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('generic.lang_maxTableOccupation') }}
        </v-card-title>
        <v-card-text>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field v-model="dialog.maxPartiesField" :label="$t('generic.lang_maxParties')"
                            :min="1"
                            :rules="[v=> (parseInt(v)>0) || this.$t('generic.lang_mustBeGreater0')]" type="number"/>
            </v-flex>
            <v-flex md12>
              <v-text-field v-model="dialog.maxPersons" :label="$t('generic.lang_maxClients')"
                            :min="1"
                            :rules="[v=> (parseInt(v)>0) || this.$t('generic.lang_mustBeGreater0')]" type="number"/>
            </v-flex>
            <v-flex md12>
              <v-checkbox v-model="dialog.dontAskForParties" :label="$t('generic.lang_dontAskForParties')">
              </v-checkbox>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider/>

        <v-card-actions>
          <v-btn color="error" text @click="dialog.config = false">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <v-btn color="success" @click="editConfig()">{{ $t('generic.lang_apply') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog.nbrParties" width="500">
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : 'grey lighten-2']" class="headline" primary-title>{{ $t('generic.lang_numberOfParties') }}
        </v-card-title>
        <v-card-text>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field v-model="dialog.nbrPartiesField" :label="$t('generic.lang_numberOfParties')"
                            :min="1"
                            :rules="[v=> (parseInt(v)>0) || this.$t('generic.lang_mustBeGreater0')]" type="number"/>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider/>

        <v-card-actions>
          <v-btn color="error" text @click="dialog.nbrParties = false">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <template v-if="activeTable!==null&&activeTable!==undefined">
            <v-btn :disabled="canOrder" color="success" @click="createTable()">
              {{ $t('generic.lang_goToOrder') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
    <v-overlay v-model="editor.loading" absolute persistent>
      <v-card color="primary" dark width="300">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p class="white--text text-center" style="margin-bottom: 5px;">{{ $t('generic.lang_loadingPleaseWait') }}...</p>

                <v-progress-linear
                    class="mb-0"
                    color="white"
                    indeterminate
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay v-model="loadingTableSelect" absolute>
      <v-progress-circular class="mx-auto" indeterminate size="150" color="primary"></v-progress-circular>
    </v-overlay>

    <BookItem ref="bookItem" :pos-type="posType"></BookItem>
  </div>
</template>


<script>
import mixin from "@/mixins/KeyboardMixIns";
import {library} from '@fortawesome/fontawesome-svg-core';
import {mapGetters, mapState} from 'vuex'

import {fal} from '@fortawesome/pro-light-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Events} from "@/plugins/events";
import VueDragResize from 'vue-drag-resize'
import BookItem from "@/mixins/pos/BookItem";
import {ENDPOINTS} from "@/config";
import {checkColorLightOrDark} from "@/plugins/checkColorLightOrDark";
import validation from "../../mixins/validation/validation";

library.add(
    fal, fas
)

export default {
  name: "RoomFloorComponent",
  components: {
    BookItem,
    VueDragResize, FontAwesomeIcon
  },
  mixins: [mixin,validation],
  props: {
    roomId: {
      type: Number,
      required: true,
    },
    savedTables: {
      type: Array,
      default: null,
    },
    statuses: {
      type: Array,
      default: null,
    },
    reservationTables:{
      default:null,
    },
    showReservationInfo:{
      type:Boolean,
      default:true,
    },
    roomTab:{
      default:0,
    }
  },
  data() {
    return {
      average: 300,
      loading: 0,
      editMod: 0,
      infoMod: false,
      loadingTableSelect: false,
      loadingEditName: false,
      id: parseInt(this.$props.roomId),
      time: 0,
      dontAskForParties: false,
      timer: null,
      activeTable: null,
      index: null,
      dialog: {
        edit: true,
        name: false,
        nbrParties: false,
        nbrPartiesField: 1,
        config: false,
        maxPersons: null,
        dontAskForParties: false,
        tableName: "",
        tableNameMessage: "",
        tableNameValid: true,
        maxPartiesField: 1,
        partyName: "Partei 1",
      },
      editor: {
        font: 100,
        shape: "rect",
        background: "#3ac47d",
        calculatedHeight: 720,
        isEditing: false,
        active: false,
        loading: false,
        paint: false,
        add: false,
        rename: false,
      },
      modify: true,
      tables: [],
      tablesTotal: [],
      palette: [
        '#7997e4',
        '#989fa4',
        '#75d6a4',
        '#5cc4ff',
        '#f9ce66',
        '#e46785',
        '#7c7a88',
        '#a182ad',
        '#71757a',
      ],
      bgPalette: [
        '#3f6ad8',
        '#6c757d',
        '#3ac47d',
        '#16aaff',
        '#f7b924',
        '#d92550',
        '#444054',
        '#794c8a',
        '#343a40',
      ],
      defaultStatuses: [
        {
          name: 'Scanned',
          color: '#7997e4',
        },
        {
          name: 'Empty',
          color: '#75d6a4',
        },
        {
          name: 'booked',
          color: '#5cc4ff',
        },
        {
          name: 'booked and paid',
          color: '#f9ce66',
        },
        {
          name: 'busy',
          color: '#e46785',
        },
      ],
      floor: {
        background: "",
      },
      posType: "gastro",
      partyName: "Partei 1",
      bee: require('@/assets/images/delivery/bee.svg'),
      reservationData:false,
      selectedTable:'',
      bgImage:'',
      roomName:'',
      tableNameFontSize:16,
    }
  },
  watch:{
    'editor.active':function (v){
      if(v){
        this.infoMod=false;
      }
    },
    infoMod(val){
      if(val){
        this.$emit('revealResInfo')
      }
    },
    showReservationInfo(val){
      this.infoMod=false;
    },
  },
  sockets: {
    "tableplan.refreshTables": function () {
      this.$emit("getTables");
    },
  },

  computed: {
    ...mapGetters({
      lockedTables:'pos/gastro/lockedTables',
    }),
    ...mapState([
      'pos',
      'api',
      'user',
    ]),
    getActive() {
      for (let i = 0, l = this.tables.length; i < l; i++) {
        let item = this.tables[i];
        if (item.active) {
          return i;
        }
      }
      return null;
    },
    getShape() {
      if (this.editor.active && this.getActive !== null) {
        let index = this.getActive;
        return (this.tables[index].type === 'round') ? "square" : "circle";
      } else if (this.editor.active && this.getActive === null) {
        return (this.editor.shape === 'round') ? "circle" : "square";
      }
      return "circle";
    },
    canOrder() {
      if (this.dialog.nbrPartiesField === null || this.dialog.nbrPartiesField === "") {
        return true;
      }
      if (parseInt(Number(this.dialog.nbrPartiesField)) > 0
          && parseInt(Number(this.dialog.nbrPartiesField)) <= parseInt(Number(this.activeTable.maxParties))) {
        return false;
      }
      return true
    },
    roomTables(){
      if(this.tables && this.tables.length>0){
        return this.tables;
      }
      return [];
    }
  },
  methods: {
    checkColorLightOrDark,
    showTable(table,index){
      let flag=true;

      if(Boolean(this.$route.params.isChange) === true && this.findTable(index)>-1){
        flag = false;
      }

      if(Boolean(this.$route.params.isChange) === true && this.lockedTables.includes(table.name)){
        flag = false;
      }

      if(Boolean(this.$route.params.isChange) === true && this.pos.gastro.table.name===table.name){
        flag = false;
      }

      return flag
    },
    orderItems(table, party, orderedItems) {
      return new Promise((resolve, reject) => {
        let FORM = {
          tableName: (table !== null ? table.name : 0),
          partyName: party.name,
          items: orderedItems,
          openItems: this.pos.gastro.openItems[party.name]
        };

        if (this.$store.getters['pos/gastro/isFromReservation']) {
          FORM.reservation_uuid = this.$store.getters['pos/gastro/reservationUUID'];
        }


        return this.axios.post(ENDPOINTS.POS.GASTRO.ORDER.CREATE, FORM).then((res) => {
          if (res.data.success || (!res.data.success && res.data.type === "warning")) {
            //EMIT SOCKET EVENT FOR KITCHENMONITOR
            this.$socket.emit("pos.createOrder", {
              table: {
                uuid: this.pos.gastro.table.uuid
              },
              items: orderedItems
            });

            Events.$emit('createOrder')

            //CHECK IF NOT TRESENVERKAUF
            if (table.name !== 0) {
              //ORDER ITEMS
              this.$store.commit("pos/gastro/setOrderedItems", {
                partyName: party.name,
                orders: orderedItems
              });
            }
          }
        });
      });
    },
    async changeTable(table) {

      if(Number(this.pos.gastro.table.name)===0){
        this.replaceOpenItems(table);
        return
      }

      //  //console.log(table);
      this.axios.post(ENDPOINTS.POS.GASTRO.CHANGETABLE, {
        sourceTable: this.pos.gastro.table.name,
        targetTable: table.name
      }).then((res) => {
        if (res.data.success) {
          this.replaceOpenItems(table)

        }else{

        }
      }).catch((err) => {
        console.log(err)
      });

      /*
      //  let currentTable = this.pos.gastro.
      let currentGastro = Object.assign(this.pos.gastro);
      const openItems = Object.assign(currentGastro.openItems);
      const orderedItems = Object.assign(currentGastro.orderedItems);
      const party = currentGastro.party;
      currentGastro.table.name = table.name;
      currentGastro.table.uuid = table.uuid;

      this.$store.dispatch("pos/gastro/setTable", {
        posType: this.posType,
        table: currentGastro.table
      }).then(() => {


        this.$store.dispatch("pos/gastro/setOpenItems", {
            party: party.name,
            orders: []
        });
        this.$store.dispatch("pos/gastro/setOrderedItems", {
            partyName: party.name,
            orders: []
        });

        this.$socket.emit("tableplan.setTable", {
            userID: this.user.userID,
            table: currentGastro.table
        });

        this.pos.gastro.party = party;
        this.pos.gastro.openItems = openItems;

        for (let elt in orderedItems)
            this.orderItems(table, {name: elt}, orderedItems[elt]);

        //EMIT TO SOCKETSERVER THAT TABLE CHANGED
        this.$socket.emit('tableplan.changeTable', {
            oldTableUUID: currentGastro.table.uuid,
            newTableUUID: table.uuid
        });

        this.$router.replace("/pos/gastro");
        });*/



    },
    replaceOpenItems(table){
      let currentGastro = Object.assign(this.pos.gastro);
      let oldTableName = this.pos.gastro.table.name;
      const openItems = Object.assign(currentGastro.openItems);
      const party = currentGastro.party;
      currentGastro.table.name = table.name;
      currentGastro.table.uuid = table.uuid;
      currentGastro.table.roomName = this.roomName;
      this.$store.dispatch("pos/gastro/setTable", {
        posType: this.posType,
        table: currentGastro.table
      }).then(() => {
        this.$store.dispatch("pos/gastro/setOpenItems", {
          party: party.name,
          orders: []
        });
        this.pos.gastro.party = party;
        this.pos.gastro.openItems = openItems;

        this.$socket.emit('tableplan.leaveTable', oldTableName);
        this.$socket.emit('tableplan.setTable', currentGastro.table.name);

        //EMIT TO SOCKETSERVER THAT TABLE CHANGED
        /*this.$socket.emit('tableplan.changeTable', {
          oldTableUUID: oldTableUUID,
          newTableUUID: table.uuid
        });*/

        this.$router.replace("/pos/gastro");
      });
    },
    findTable(index) {


      if (this.savedTables.length > 0) {
        for (let i = 0; i < this.savedTables.length; i++) {
          if (this.tables[index].name === this.savedTables[i].name) {
            return i;
          }
        }
      }
      return -1;
    },
    getSource(index) {

    },
    getColor(index) {
      if (this.$props.savedTables !== null) {
        let percentage = 0.80,
            hue0 = 120, hue1 = 0;
        let now = Date.now();
        let roomTime = this.time * 60 * 1000;
        let tableTime = this.$props.savedTables[this.findTable(index)].timestamp * 1000;
        percentage = (((now - tableTime) / roomTime)).toFixed(2);
        percentage = (percentage > 1) ? 1 : percentage;
        let hue = (percentage * (hue1 - hue0)) + hue0;
        return ['hsl(' + hue + ', 100%, 40%)', Math.floor(percentage * 100).toFixed(2)];
      }
    },
    getTotal(index) {
      let tableUUID = this.tables[index].uuid;

      return this.$props.savedTables[this.findTable(index)].total;
    },
    getRoomLayout() {
      let self = this;
      this.editor.loading = true;
      this.axios.post('get/settings/roomDetails/', {
        roomID: this.id,
      }).then(res => {
        if (res.status === 200) {
          this.tableNameFontSize=res.data.formfillData.textFields.tableNameFontSize?res.data.formfillData.textFields.tableNameFontSize:16;
          this.roomName=res.data.formfillData.textFields.roomName
          if (res.data.formfillData.textFields.interior_object !== '{}') {
            let interior = JSON.parse(res.data.formfillData.textFields.interior_object);
            let parentWidth = this.$vuetify.breakpoint.width, parentHeight = this.$vuetify.breakpoint.height - 112;

            for (let i = 0; i < interior.tables.length; i++) {
              interior.tables[i].active = false;
              interior.tables[i].left = (parseFloat(interior.tables[i].left / 100) * parseFloat(parentWidth));
              interior.tables[i].top = (parseFloat(interior.tables[i].top / 100) * parseFloat(parentHeight));
              interior.tables[i].width = (parseFloat(interior.tables[i].width / 100) * parseFloat(parentWidth));
              interior.tables[i].height = (parseFloat(interior.tables[i].height / 100) * parseFloat(parentHeight));
            }

            this.editor.background = interior.background;
            this.tables = interior.tables;

            this.dontAskForParties = res.data.formfillData.textFields.dontAskForParties;
            this.bgImage=res.data.formfillData.textFields.bgImage;
            //CALL SOCKET IO TO GET LOCKED TABLES
            this.$socket.emit("tableplan.getLockedTables");
          }
        }
      }).catch(err => {
      }).finally(()=>{
        this.editor.loading = false;
      })
    },
    lockTable(index) {
      if (!this.editor.active) {
        // CHECK WEBSOCKET LOCK
        if (this.lockedTables.findIndex((tableName) => tableName === this.tables[index].name) > -1)
          return true;

        // CHECK SAVED TABLE
        if (this.findTable(index) !== -1) {
          let table = this.savedTables[this.findTable(index)];

          if(parseInt(table.soldBy) > 0)
            if ((table.soldBy !== this.user.userID))
              return true;
        }

        return false;
      } else {
        return true;
      }
    },
    lockRoomTable(index) {
      if (!this.editor.active) {
        return !(this.tables[index].user === this.user.userID || this.tables[index].user === 0)
      } else {
        return true;
      }
    },
    timeEnded(index) {
      let now = Date.now();
      let tableTime = this.$props.savedTables[this.findTable(index)].timestamp * 1000;
      let roomTime = this.time * 60 * 1000;
      return (now > tableTime + roomTime);
    },
    showPartiesCount(item, index) {
      if(this.infoMod){
        this.selectedTable=item;
        this.reservationData=this.reservationTables.hasOwnProperty(item.name);
        return;
      }

      if (!this.editor.active) {
        //CHECK SOCKET STATE
        if (this.lockedTables.findIndex((tableName) => tableName === this.tables[index].name) > -1)
          return;

        this.activeTable = item;
        this.index = index;

        if (this.$route.params.isChange) {
          this.changeTable(item)
        } else {
          if (this.findTable(this.index) >= 0) {
            this.createTable();
            return;
          }

          if (this.dontAskForParties) {
            if (this.tables[index].dontAskForParties) {
              this.createTable();
            } else {
              this.dialog.nbrParties = true;
            }
          } else {
            if (this.tables[index].dontAskForParties) {
              this.createTable();
            } else {
              this.dialog.nbrParties = true;
            }
          }
        }
      }
    },
    selectItem(ean) {
      this.$refs.bookItem.selectItemEAN(ean);
    },
    createTable() {
      if (!this.editor.active) {
        //CHECK IF TABLE IS LOCKED
        if (!this.$store.getters['permissions/checkPermission']('allowAccessAllTables')) {
          if (this.lockTable(this.index))
            return;
        }
        //CHECK IF WE ALREADY ACCESSING A TABLE
        if(this.loadingTableSelect)
          return;

        this.loadingTableSelect=true;


        let date = new Date();
        let parties = [];
        let time = date.getHours() + ":" + date.getMinutes();
        this.tables[this.index].user = this.user.userID;

        if (this.findTable(this.index) >= 0) {
          parties = this.savedTables[this.findTable(this.index)].parties;
        } else {
          if (!Number(this.tables[this.index].dontAskForParties)) {
            for (let i = 0; i < Number(this.dialog.nbrPartiesField); i++) {
              parties.push({
                name: "Partei " + (i + 1),
              })
            }
          } else {
            parties.push({
              name: "Partei 1",
            });
          }
        }
       // console.log(this.tables[this.index])
        this.$store.dispatch("pos/gastro/setTable", {
          posType: this.posType,
          table: {
            uuid: this.tables[this.index].uuid,
            name: this.tables[this.index].name.toString(),
            parties: parties,
            totalParties: 1,
            total: 0,
            date: time,
            roomName:this.roomName,
            source:(this.findTable(this.index) >= 0)?this.savedTables[this.findTable(this.index)].source:'pos',
          }
        }).then(() => {
          this.$socket.emit("tableplan.setTable", this.tables[this.index].name);
          this.$store.commit('pos/gastro/setRoom', {
            index:this.roomTab,
            id:this.id
          })
          this.$router.replace("/pos/gastro");
        }).finally(()=>{
          this.loadingTableSelect=false;
        })
      }
    },
    alignElements(event, index) {
      this.tables[index].lineHeight = event.height;
    },
    resize(event, index) {
      this.alignElements(event,index)
      this.tables[index].top = event.top;
      this.tables[index].left = event.left;
      this.tables[index].width = event.width;
      this.tables[index].height = event.height;
    },
    disableEdit() {
      this.editor.isEditing = false;
      this.editor.paint = false;
    },
    edit(event) {
      if (event.type === "mouseenter") {
        this.editor.isEditing = true;
      } else if (event.type === "mouseleave") {
        this.editor.isEditing = false;
      } else {
        this.editor.isEditing = true;
      }
    },
    toggleEdit() {
      if (!this.editor.loading) {
        this.editor.active = !this.editor.active;
        if (!this.editor.active) {
          for (let i = 0, l = this.tables.length; i < l; i++) {
            this.tables[i].draggable = false;
            this.tables[i].resizable = false;
            this.tables[i].active = false;
          }
          this.updateRoomLayout();
        }
      }
    },
    calculatePos(event, index) {
      this.tables[index].top = event.top;
      this.tables[index].left = event.left;
    },
    updateRoomLayout() {

      let interior = {
        background: this.editor.background,
        tables: JSON.parse(JSON.stringify(this.tables)),
      };

      let parentWidth = this.$vuetify.breakpoint.width, parentHeight = this.$vuetify.breakpoint.height - 112
      for (let i = 0; i < interior.tables.length; i++) {
        interior.tables[i].active = false;
        interior.tables[i].left = (parseFloat(interior.tables[i].left).toFixed(2) / parseFloat(parentWidth).toFixed(2)) * 100;
        interior.tables[i].top = (parseFloat(interior.tables[i].top).toFixed(2) / parseFloat(parentHeight).toFixed(2)) * 100;
        interior.tables[i].width = (parseFloat(interior.tables[i].width).toFixed(2) / parseFloat(parentWidth).toFixed(2)) * 100;
        interior.tables[i].height = (parseFloat(interior.tables[i].height).toFixed(2) / parseFloat(parentHeight).toFixed(2)) * 100;
      }
      this.editor.loading = true;
      this.axios.post('update/settings/roomLayout/', {
        roomID: this.id,
        interior_object: interior,
      }).then(res => {
        this.editor.loading = false;
        Events.$emit("showSnackbar", {
          message: this.$t('settings.lang_roomSaved'),
          color: "success"
        });
      }).catch(err => {
        this.editor.loading = false;
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      })
    },
    handleClick(event, index) {
      if (!this.editor.active) {
        //this.selectTable()
      }
    },
    setActive(index) {
      if (this.editor.active) {
        for (let i = 0, l = this.tables.length; i < l; i++) {
          if (i === index) {
            this.tables[i].active = true;
            this.tables[i].draggable = true;
            this.tables[i].resizable = true;
            if (this.findTable(i) === -1) {
              this.modify = true;
            } else {
              this.modify = false;
            }
            continue;
          }
          this.tables[i].draggable = false;
          this.tables[i].resizable = false;
          this.tables[i].active = false;

        }
      }
    },
    setInactive(index) {
      if (!this.editor.isEditing) {
        this.tables[index].active = false;
      }
    },
    addTable(name) {
      if (this.editor.active) {
        this.tables.push({
          width: 150, height: 100,
          top: 100, left: 100,
          name: name.toString(), maxParties: 1, dontAskForParties: this.dontAskForParties,
          type: this.editor.shape, color: '#5cc4ff',
          user: 0, total: 0,
          maxPersons: 5, uuid: '',
          active: false, draggable: false, resizable: false, lineHeight: 100,
        });
        this.setActive(this.tables.length - 1);
      }
    },
    deleteTable() {
      if (this.editor.active && this.getActive !== null) {
        this.$swal({
          title: this.$t('generic.lang_confirmDeleting'),
          text: this.$t('generic.lang_deleteSelectedTableText'),
          icon: "warning",
          cancelButtonText: this.$t('generic.lang_cancel'),
          confirmButtonText: this.$t('generic.lang_delete'),
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            let index = this.getActive;
            this.tables.splice(index, 1);
          },
          allowOutsideClick: () => !this.$swal.isLoading,
        });
      }
    },
    changeShape() {
      if (this.editor.active && this.getActive !== null) {
        let index = this.getActive;
        this.tables[index].type = (this.tables[index].type === "rect") ? "round" : "rect";
      } else {
        this.editor.shape = (this.editor.shape === "rect") ? "round" : "rect";
      }
    },
    changeColor(color, key) {
      if (this.editor.active && this.getActive !== null) {
        let index = this.getActive;
        this.tables[index].color = color;
      }
      if (this.getActive === null) {
        this.editor.background = this.bgPalette[key];
      }
    },
    tableExist(name) {
      for (let i = 0, l = this.tables.length; i < l; i++) {
        if (this.tables[i].name.toString() === name.toString()) {
          return true;
        }
      }
      return false;
    },
    async clone() {
      if (this.editor.active && this.getActive !== null) {
        this.editor.loading = true;
        let index = this.getActive;
        let clone = {...(this.tables[index])};
        let name = '';
        if (isNaN(this.tables[index].name)) {
          if (!this.tables[index].name.includes('_dup')) {
            clone.name = this.tables[index].name + '_dup';
          }
        } else {
          clone.name = parseInt(this.tables[index].name) + 1;
        }

        name = clone.name;
        while (await this.isDup(name)) {
          if (isNaN(name)) {
            let dupCntr = name.slice(name.indexOf('_dup') + 4)
            dupCntr = (dupCntr !== "") ? parseInt(dupCntr) + 1 : "1"
            name = this.tables[index].name.slice(0, this.tables[index].name.indexOf('_dup')) + '_dup' + dupCntr;
          } else {
            name = parseInt(name) + 1;
          }
        }

        clone.name = name;
        clone.uuid = '';
        clone.user = 0;
        clone.total = 0;
        clone.top = clone.top + 10;
        clone.left = clone.left + 10;
        this.editor.loading = false;
        this.tables.push(clone);
        this.setActive(this.tables.length - 1);

      }
    },
    async isDup(name) {
      let state = true;
      //check if table already in the current room
      if (this.tableExist(name)) {
        return true
      }
      //check if table is exiting in other rooms
      await this.axios.post(ENDPOINTS.POS.GASTRO.UNIQUE, {
        tableNo: name,
      }).then(res => {
        state = (res.data.status !== 'SUCCESS')
      })
      return state;
    },
    showEditName(mode) {
      if (mode === 0) {
        this.dialog.edit = false;
        this.dialog.name = true;
        this.editMod = 0
      } else if (this.editor.active && this.getActive !== null) {
        this.dialog.edit = true;
        this.dialog.name = true;
        this.editMod = 1
        this.dialog.tableName = this.tables[this.getActive].name;
      }
      this.dialog.tableNameValide = true;
      this.dialog.tableNameMessage = "";
    },
    editName(mode) {
      if (this.tableExist(this.dialog.tableName)) {
        this.dialog.tableNameValide = false;
        this.dialog.tableNameMessage = this.$t('generic.lang_tableAlreadyExists')
        return;
      }
      if (this.editMod === 0) {
        this.loadingEditName = true
        this.axios.post(ENDPOINTS.POS.GASTRO.UNIQUE, {
          tableNo: this.dialog.tableName,
        }).then(res => {
          if (res.data.status === 'SUCCESS') {
            this.dialog.tableNameValide = true;
            this.dialog.tableNameMessage = "";
            this.addTable(this.dialog.tableName)
            this.dialog.tableName = "";
            this.dialog.name = false;
          } else {
            this.dialog.tableNameValide = false;
            this.dialog.tableNameMessage = res.data.code
          }
        }).catch(err => {
          this.dialog.tableNameValide = false;
        }).finally(() => {
          this.loadingEditName = false
        })
      } else {
        let index = this.getActive;
        this.loadingEditName = true
        this.axios.post(ENDPOINTS.POS.GASTRO.UNIQUE, {
          tableNo: this.dialog.tableName,
        }).then(res => {
          if (res.data.status === 'SUCCESS') {
            this.dialog.tableNameValide = true;
            this.dialog.tableNameMessage = "";
            this.tables[index].name = this.dialog.tableName;
            this.dialog.tableName = "";
            this.dialog.name = false;
          } else {
            this.dialog.tableNameValide = false;
            this.dialog.tableNameMessage = res.data.code
          }
        }).catch(err => {
          this.dialog.tableNameValide = false;
        }).finally(() => {
          this.loadingEditName = false
        })
      }
    },
    showConfig() {
      if (this.editor.active && this.getActive !== null) {
        this.dialog.config = true;
        this.dialog.maxPartiesField = this.tables[this.getActive].maxParties;
        this.dialog.maxPersons = this.tables[this.getActive].maxPersons;
        this.dialog.dontAskForParties = this.tables[this.getActive].dontAskForParties;
      }
    },
    editConfig() {
      let index = this.getActive;
      if (parseInt(this.dialog.maxPartiesField) > 0) {
        this.tables[index].maxParties = parseInt(Number(this.dialog.maxPartiesField));
        this.tables[index].maxPersons = parseInt(Number(this.dialog.maxPersons));
        this.tables[index].dontAskForParties = this.dialog.dontAskForParties;
        this.dialog.maxPartiesField = 1;
        this.dialog.maxPersons = 1;
        this.dialog.dontAskForParties = false;
        this.dialog.config = false;
      }
    },
    sync() {
      let self = this;
      this.timer = setInterval(() => {
        self.loading += 0.1;
      }, 1000 * 30);
    },

    isTableDuplicated(name) {
      this.axios.post("get/settings/duplicateTable/", {
        tableNo: name.toString(),
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.status === "SUCCESS") {
            return false;
          } else {
            return true;
          }
        }
      }).catch((err) => {
        return null;
      })
      return null;
    },
    getTableTotal() {
      this.axios.post("get/settings/tableOverview/tableTotal/")
          .then((res) => {

          }).catch((err) => {

      })
    },
    getTime() {
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.GET, {roomID: this.id})
          .then(res => {
            if (res.status === 200) {
              this.time = res.data.formfillData.textFields.roomTime;
            }
          }).catch(err => {
      });
    },
    getTableDailyTotal(index) {
      let table = this.tablesTotal;
      if (table.length > 0) {
        for (let i = 0; i < table.length; i++) {
          if (this.tables[index].name.toString() === table[i].tableName.toString()) {
            return i;
          }
        }
      }
      return -1;
    },
    getGoalState(index) {
      let i = this.getTableDailyTotal(index);
      let table = this.tablesTotal;
      if (i !== -1) {
        let percent = ((table[i].total / this.average) * 100).toFixed(2);
        return (percent > 100) ? 100 : percent;
      }
      return 0;
    },
    getStatusColor(index,name){
      let table=this.findTable(index);
      let statuses=[];
      if(!(this.statuses&&this.statuses.length>0)){
        statuses=this.defaultStatuses;
      }else{
        statuses=this.statuses
      }
      if(table>-1){
        if (this.lockedTables.includes(table.name)){
          return statuses[4].color||this.defaultStatuses[4].color
        }else{
          let isPaid = true;
          if(this.savedTables[table].parties&&this.savedTables[table].parties.length>0)
            this.savedTables[table].parties.forEach(party=>{
              if(!party.is_payed){
                isPaid=false;
              }
            })
          else
            isPaid=false;

          if(isPaid){
            return statuses[3].color||this.defaultStatuses[3].color
          }else{
            return statuses[2].color||this.defaultStatuses[2].color
          }
        }
      }
      if (this.lockedTables.includes(name)){
        return statuses[4].color||this.defaultStatuses[4].color
      }
      return statuses[1].color||this.defaultStatuses[1].color;
    },
  },
  mounted() {

    this.sync();
    this.getRoomLayout();
    this.getTableTotal();
    this.getTime();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>

<style scoped>
/*@media (max-width: 900px) {
  .responsiveTable {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    position: relative;
    top: 100px;
    width: 600px !important;
    height: 400px !important;
    left: -100px;
  }
}*/

.cont {
  width: 100%;
}

.room-table {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.rect {
  border-radius: 10px;
}

.round {
  border-radius: 600px;
}

.floor {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center  !important;
  background-repeat: no-repeat  !important;
  background-size: cover !important;
}

.room-table-filter {
  display: block;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px 0px 3px 3px;
}

.table-name {
  overflow: hidden;
  color: white;
  user-select: none;
  overflow-wrap: break-word;
}

.table-client {
  text-align: center;
  height: 20px;
  min-width: 20px;
  font-size: 14px;
  border-radius: 50%;
  margin-bottom: 5px;
  background: black;
  color: white;
  opacity: 0.2;
  z-index: 0;
}

.table-total {
  margin-top: 5px;
  text-align: center;
  height: 20px;
  border-radius: 600px;
  min-width: 40px;
  background: black;
  color: white;
  opacity: 0.2;
  z-index: 0;
  font-size: 14px;
  user-select: none;
}

.table-state {
  text-align: center;
  height: 20px;
  width: 30px;
  z-index: 0;
  font-size: 20px;
  user-select: none;
}

.table-timer {
  text-align: center;
  height: 20px;
  width: 30px;
  z-index: 0;
  font-size: 20px;
  color: white;
  user-select: none;
}

.editor-btn {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  font-size: 20px;
  margin: 8px;
  line-height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: lightgrey;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.info-btn {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  font-size: 20px;
  margin: 8px;
  line-height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: lightgrey;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.editor-option {
  position: absolute;
  top: 0;
  left: 40px;
  margin: 8px;
  line-height: 34px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.localbee-img{
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  margin-bottom: 5px;
  background: black;
  color: white;
  opacity: 0.2;
}

.editor-option btn {
  position: relative;
  width: 32px;
  display: inline-block;
  cursor: pointer;
  margin-right: -4px;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  transition: all 150ms linear;
}

.activated {
  background: #444;
  border-color: transparent;
  color: white;
}

.color-palette {
  position: absolute;
}

.swatch-holder-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.swatch-holder {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: 5px 5px 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  opacity: .7;
  display: inline-block;
  border-radius: 30px;
}

.bg-default {
  background-color: #098650;
}

.drag {
  cursor: move !important;
}

.nothing {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

}

.blink_me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
