<template>
  <v-container fluid>
    <v-row class="px-6 pb-4">
      <v-col cols="12" class="pt-3">
        <v-row>
          <v-col sm="12" class="pa-0 ma-0">
            <div style="height: 0 !important; visibility: hidden !important;">
              <v-calendar
                  ref="calendar"
                  v-model="requested_date"
                  color="primary"
                  type="day"
              ></v-calendar>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div
                class="pa-0 px-0 ma-0"
            >
              <v-btn
                  class="mr-0"
                  color="grey darken-2"
                  outlined
                  @click="setToday"
              >
                {{ $t('generic.lang_today') }}
              </v-btn>
              <v-btn
                  color="grey darken-2"
                  fab
                  icon
                  text
                  @click="$refs.calendar.prev()"
              >
                <v-icon large>
                  mdi-chevron-left
                </v-icon>
              </v-btn>

              <v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker=true">
                {{ new Date(requested_date).getDate() }}
              </v-btn>


              <v-btn
                  color="grey darken-2"
                  fab
                  icon
                  small
                  @click="$refs.calendar.next()"
              >
                <v-icon large>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <strong v-if="$refs.calendar" class="font-weight-bolder">
                {{ $refs.calendar.title }}
              </strong>
            </div>
          </v-col>

          <v-col cols="12" sm="6" class="text-right">
            <v-spacer/>
            <v-btn
                :disabled="Array.isArray(this.reservations) && this.reservations.length === 0 || !Array.isArray(this.reservations)"
                @click="pdf" class="mx-auto mr-3" color="warning" depressed>
              {{ $t('generic.lang_receiptPDFDownload') }}
            </v-btn>

            <v-btn to="/reservations" class="mx-auto" color="success" depressed>
              {{ $t('generic.lang_Showall') }}
            </v-btn>
          </v-col>
        </v-row>


      </v-col>
      <!-- filters -->
      <v-col cols="12" class="d-flex">
        <v-row class="pt-0 mt-0">

          <!-- search filter -->
          <v-col cols="12" md="6" sm="6">
            <b-form-input v-model="search" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :placeholder="$t('customers.lang_nav_searchcust')+' ...'"
                          size="lg" @focus="showTouchKeyboard"/>
          </v-col>
          <!-- types filter -->
          <v-col cols="12" md="6" sm="6">
            <b-form-select v-model="type" :label-field="$t('generic.lang_constReservationType')" :placeholder="$t('generic.lang_type')" size="lg">
              <b-form-select-option :value="-1">{{ $t('generic.lang_allTypes') }}</b-form-select-option>
              <b-form-select-option v-for="t in this.types" :key="t.uuid" :value="t.uuid">{{ t.name }}
              </b-form-select-option>
            </b-form-select>
          </v-col>

        </v-row>
      </v-col>

      <v-col cols="12">
        <v-data-table :search.sync="this.search" :loading="loadData || loading" :headers="this.headers"
                      :items="this.reservations">
          <template v-slot:item.name="{item}">
            <v-list-item>
              <v-list-item-avatar color="primary">
                <v-icon color="white">person</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="primary--text font-weight-bold">{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.phone }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item.resType_name="{item}">
            <v-chip :color="item.resType_obj.color" class="rounded-lg">
              <span class="white--text">{{ item.resType_name }}</span>
            </v-chip>
          </template>


          <template v-slot:item.tables="{item}">
            <div>
              {{ item.tables.map((elt) => elt.name).join(' , ') }}
            </div>
          </template>

          <template v-slot:item.prepared_by="{item}">
            <v-chip color="success" v-if="item.prepared === 1">{{ item.prepared_by_name }}</v-chip>
            <v-chip color="warning" v-else>{{ $t('generic.lang_notPrepared') }}</v-chip>
          </template>

          <template v-slot:item.action="{item}">
            <v-icon :disabled="loading" @click="initOrder(item)" color="primary">
              visibility
            </v-icon>
          </template>
        </v-data-table>
      </v-col>

      <!-- reservations datatable -->
    </v-row>

    <reservation-plan-tables-component @updated="updated" v-if="this.selected_reservation" v-model="tables_dialog"
                                       :reservation="this.selected_reservation.uuid"
                                       :persons="this.selected_reservation.person"
                                       :date="this.selected_reservation.date" :time="this.selected_reservation.time"
                                       :reservation_type="this.selected_reservation.resType"/>

    <reservation-pdf-component ref="pdf" :reservations="this.reservations" :date="new Date(this.requested_date)"/>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>


    <!-- date picker dialog -->
    <v-dialog v-model="picker" max-width="400px" scrollable>
      <v-card align="center" class="pa-0 ma-0">
        <v-card-title class="pa-0 px-0 ma-0">
          <v-spacer/>
          <v-btn icon @click="picker=false">
            <v-icon color="error">
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-date-picker
              v-model="requested_date"
              event-color="green lighten-1"
              width="100%"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/**
 * import plugins
 */
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheckCircle} from '@fortawesome/pro-duotone-svg-icons'
import {mapState} from 'vuex';
import ReservationPlanTablesComponent from "@/components/pos/ReservationPlanTablesComponent";
import ReservationPdfComponent from "@/components/reservation/reservations/ReservationPdfComponent";

library.add(faCheckCircle)

export default {
  name: "ReservationPlanComponent",
  components: {ReservationPdfComponent, ReservationPlanTablesComponent},
  mixins: [mixin],
  data: () => ({
    current_date: new Date(),
    types: [],
    type: -1,
    types_load: false,
    reservations: [],
    loadData: false,
    search: "",
    loading: false,
    selected_reservation: null,
    tables_dialog: false,
    requested_date: "",
    picker: false,
    items: []
  }),
  watch: {
    search(val) {
      val & this.loadRes();
    },
    type(val) {
      val & this.loadRes();
    },
    requested_date(val) {
      val & this.loadRes();
    }
  },
  computed: {
    ...mapState([
      'pos'
    ]),
    headers: function () {
      return [
        {
          text: this.$t('customers.lang_customers'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('generic.lang_type'),
          value: 'resType_name',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_noOfPersons'),
          value: 'person',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_time'),
          value: 'time',
          width: "200"
        },
        {
          text: this.$t('generic.lang_time'),
          value: 'end_at',
          width: "200"
        },
        {
          text: this.$t('generic.lang_table'),
          value: 'tables',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_preparedBy'),
          value: 'prepared_by',
          sortable: false,
        },
        {
          text: "",
          value: 'action',
          align: "right",
          sortable: false,
          width: "200"
        },
      ]
    },
  },
  methods: {
    setToday() {
      let dt = new Date();
      this.requested_date = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    pdf() {
      this.$refs.pdf.generatePDF();
    },
    updated() {
      this.loadRes();
      this.selected_reservation = null;
    },
    /**
     * get all available reservation types
     */
    loadTypes() {
      this.types_load = true;
      this.types = [];
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.types = res.data.types;

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.types_load = false;
      })
    },
    /**
     * get all reservations for today
     */
    loadRes() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.GET
          + '?type=' + this.type + '&day=' + this.requested_date + '&search=' + this.search
      ).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.reservations = [];
          this.reservations = res.data.reservations;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
      })
    },
    async selectTable(table) {
      let tmp = Object.assign({},table);
      await this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GETROOMBYTABLE,{
        tableName:table.name,
      }).then(res=>{
        if(res.data.status){
          tmp.roomName = res.data.roomName;
        }
      })
      this.$store.dispatch("pos/gastro/setTable", {
        posType: "gastro",
        table: tmp
      }).then(() => {
        this.$router.replace("/pos/gastro");
      });
    },
    /**
     * filter available tables and reserved tables (has already an order)
     * @param tables
     * @returns {Promise<unknown>}
     */
    async checkAvailableTables(tables) {
      return new Promise(((resolve, reject) => {
        //check if no table provided
        if (!Array.isArray(tables)) reject(false);
        this.loading = true;
        //request for tables that already have orders
        this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then((res) => {//todo: add loader
          if (res.data.success) {
            const all_locked_tables = res.data.tables;
            const unavailable_tables = all_locked_tables.filter(
                (elt) => tables.includes(elt.name)).map((elt) => elt.name
            );
            const available_tables = tables.filter((table) => !unavailable_tables.includes(table));

            //no table available
            if (Array.isArray(available_tables) && available_tables.length <= 0) {
              this.loading = false;
              reject({
                success: false,
                message: this.$t('generic.lang_reservedTablesAreUnavailableCurrently')
              });
            }

            //resolve promise with available and unavailable tables then the user can choice to continue with only available ones
            this.loading = false;
            resolve({
              available: available_tables,
              unavailable: unavailable_tables
            });
          } else {
            this.loading = false;
            reject(this.$t('generic.lang_errorOccurred'));
          }


        }).catch((err) => {
          this.loading = false;
          reject(err.message);
        })
      }))
    },
    async setCustomer(id) {
      await this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: id
      }).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("pos/setCustomer", {
            posType: 'gastro',
            customer: res.data.customer,
            setCustomerOnly:true,
          })
        }
      }).catch(() => {

      });
    },
    async bookItems(items) {
      new Promise((resolve => {
        items.forEach(async (item) => {
          await this.$store.dispatch("pos/gastro/bookItem", item);
        })
        resolve(true);
      }))
    },
    async setGastroOrder(reservation, table) {
      let tmp_items = [];

      if (Array.isArray(reservation.items) && reservation.items.length > 0) tmp_items = reservation.items.map((item) => item.ware_id);

      //filter items from vuex and set quantity
      let items = this.items.filter((elt) => {
        if (tmp_items.includes(elt.id)) {
          //get item quantity
          let quantity = 1;
          if (Array.isArray(reservation.items) && reservation.items.length > 0) {
            let item = reservation.items.find((item) => item.ware_id === elt.id);
            if (item)
              quantity = parseInt(item.quantity);
          }
          elt.weight = quantity;
          elt.mealSizeID = 0;
          elt.discount = 0;
          elt.course = null;
          elt.individualBookingNo = 0;
          elt.serialNo = 0;
          elt.originalSellPrice = elt.sellPrice;
          elt.selectedExtras = []; //TODO: we have to delete this line the user can select extras from reservation
          return elt
        }
        return false;
      });


      //set default party
      table.parties = [{
        name: reservation.name
      }]

      this.selectTable(table);

      //set this customer as current party
      await this.$store.dispatch("pos/gastro/changeParty", {
        posType: "gastro",
        party: this.pos.gastro.party
      });

      //set order freeText
      this.$store.commit('pos/gastro/setFreetext', reservation.note);

      //book items
      await this.bookItems(items).then(async () => {


        //set order as reservation
        this.$store.commit("pos/gastro/setOrderAsReservation", true);
        this.$store.commit("pos/gastro/setReservation", reservation.uuid);


        //set this customer as current party
        await this.$store.dispatch("pos/gastro/changeParty", {
          posType: "gastro",
          party: this.pos.gastro.party
        });


        //set customer
        await this.setCustomer(reservation.customer_id);
      })


    },
    showTablesDialog(reservation) {
      this.selected_reservation = {...reservation};
      this.tables_dialog = true;
    },
    async initOrder(reservation) {

      //filter reserved tables (name)
      const reserved_tables = reservation.tables.map((table) => table.name);
      /**
       * check if reserved tables are available or not then apply the logic
       */
      await this.checkAvailableTables(reserved_tables).then((res) => {

        if (Array.isArray(res.unavailable) && res.unavailable.length > 0) {
          this.$swal({
            title: this.$t('generic.lang_unavailableTables'),
            text: res.unavailable.join(' , ') + ' ' + this.$t('generic.lang_areNotAvailableDoYouWantToContinueOnlyWith') + ': ' + res.available.join(' , '),
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: this.$t('generic.lang_yes'),
            cancelButtonText: this.$t('generic.lang_no'),
            preConfirm: (val) => {
              if (val)
                this.setGastroOrder(reservation, {
                  name: res.available[0],
                  parties: []
                })
            },
            allowOutsideClick: () => !this.$swal.isLoading,
          });
        } else {
          this.setGastroOrder(reservation, {
            name: res.available[0],
            parties: []
          })
        }

      }).catch((err) => {

        if (Object.prototype.hasOwnProperty.call(err, 'success')) {
          const self = this;
          this.$swal({
            title: this.$t('generic.lang_unavailableTables'),
            text: this.$t('generic.lang_doYouWantToUpdateTables'),
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: this.$t('generic.lang_yes'),
            cancelButtonText: this.$t('generic.lang_no'),
            preConfirm: (val) => {
              if (val)
                self.showTablesDialog(reservation);
            },
            allowOutsideClick: () => !this.$swal.isLoading,
          });
          return;
        }


        Events.$emit("showSnackbar", {
          message: err,
          color: "error"
        });
      });
    }
  }
  ,
  async mounted() {
    this.items = await this.$store.dispatch("items/getItems");

    this.setToday();
    this.loadTypes();
    this.loadRes();
  },

}
</script>

<style scoped>

</style>
