<template>
  <div>

    <v-layout v-if="showDiscountButtons" wrap>
      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('7')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">7</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('8')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">8</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('9')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">9</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton(`${discountsMatrix && discountsMatrix.discount_1 || 10}`)" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">{{ discountsMatrix && discountsMatrix.discount_1 || 10 }}%</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('4')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">4</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('5')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">5</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('6')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">6</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton(`${discountsMatrix && discountsMatrix.discount_2 || 15}`)" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">{{ discountsMatrix && discountsMatrix.discount_2 || 15 }}%</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('1')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">1</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('2')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">2</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('3')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">3</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton(`${discountsMatrix && discountsMatrix.discount_3 || 20}`)" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">{{ discountsMatrix && discountsMatrix.discount_3 || 20 }}%</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="backspace" tile ripple flat class="pa-0" height="100%">
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"><font-awesome-icon :icon="['fas','backspace']"/></p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton('0')" tile ripple flat class="pa-0" height="100%">
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">0</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="resetField" tile ripple flat class="pa-0" height="100%">
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" style="color: #D92550;">CLR</p>
        </v-card>
      </v-flex>
      <v-flex style="padding: 1px;" xs3 md3>
        <v-card :disabled="disabled"  @click="clickButton(`${discountsMatrix && discountsMatrix.discount_4 || 25}`)" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">{{ discountsMatrix && discountsMatrix.discount_4 || 25 }}%</p>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="numpadOnly" wrap>
      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('7')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">7</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('8')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">8</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('9')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">9</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('4')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">4</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('5')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">5</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('6')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">6</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('1')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">1</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('2')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">2</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('3')" tile ripple flat class="pa-0" height="100%" >
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">3</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="backspace" tile ripple flat class="pa-0" height="100%">
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"><font-awesome-icon :icon="['fas','backspace']"/></p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="clickButton('0')" tile ripple flat class="pa-0" height="100%">
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">0</p>
        </v-card>
      </v-flex>

      <v-flex style="padding: 1px;" xs4 md4>
        <v-card :disabled="disabled"  @click="resetField" tile ripple flat class="pa-0" height="100%">
          <p class="numpadText ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" style="color: #D92550;">CLR</p>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<style scoped>
.numpadText {
  user-select: none;
}

@media only screen and (max-width: 1100px) {
  .numpadText {
    font-family: "Roboto", sans-serif;
    background: #fcfcfd;
    font-size: 20px;
    text-align: center;
    padding: 20%;
    color: #2a5298;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }
}

@media only screen and (min-width: 1101px) {
  .numpadText {
    font-family: "Roboto", sans-serif;
    background: #fcfcfd;
    font-size: 25px;
    text-align: center;
    padding: 25%;
    color: #2a5298;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }
}
</style>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faBackspace,} from "@fortawesome/pro-solid-svg-icons";

library.add(
    faBackspace,
)

export default {
  name: "NumpadComponent",
  props:{
    showDiscountButtons:{
      type:Boolean,
      default:false,
    },
    numpadOnly:{
      type:Boolean,
      default:false,
    },
    disabled:{
      type:Boolean,
      default:false,
    },
    discountsMatrix: {
      default: () => {
        return {
          discount_1: 10,
          discount_2: 15,
          discount_3: 20,
          discount_4: 25,
        };
      },
      type: Object
    },
  },
  components:{
    "font-awesome-icon":FontAwesomeIcon,
  },
  data() {
    return {
      value:""
    }
  },
  watch: {
    value() {
      this.$emit("updateValue", this.value);
    }
  },

  methods: {
    clickButton(button) {

      if(button > 9){
        this.value = button;
      }else {
        this.value += button;
      }
      this.$emit("clickButton", button);
    },
    setValue(val){
      this.value=val;
    },
    backspace() {
      if(this.value===null){
        return;
      }

      let tmp=this.value.toString();
      tmp=[...tmp];
      tmp.pop();
      tmp=tmp.join("");
      this.value=tmp;

    },
    resetField(){
      this.value="";
      this.$emit('resetField');
    }
  }
}
</script>