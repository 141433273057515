<template>
  <div>
    <v-dialog v-model="showDialog" persistent scrollable max-width="400"
      @keydown.enter="!valid ? null : createVoucher()">
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>{{ $t('erp.lang_nav_voucherCards') }}</v-toolbar-title>
          <v-spacer />

          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="ma-0" />
        <v-card-text>
          <v-form v-model="valid" lazy-validation @submit.prevent ref="giftCardForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    v-if="giftCardID === undefined" v-model="scannedGiftCardID" outlined filled
                    :rules="[rules.required, isValideUUID(scannedGiftCardID) ? rules.uuid : isRFIDModuleActive || rules.maxNumbers(scannedGiftCardID, 7)]"
                    :label="$t('erp.lang_GiftCardNo')" />

                  <v-text-field v-model="voucherValue" outlined filled type="number" :rules="[rules.required]"
                    :label="$t('erp.lang_GiftCardValue')" />
                  <NumpadOnly ref="numpad" @updateValue="numpadUpdateValue"
                    @clickCustomButton="numpadClickedCustomButton" @resetField="numpadResetField"
                    :custom-buttons="customButtons"></NumpadOnly>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="createVoucher" :disabled="!valid">{{ $t('generic.lang_next') }}
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
        :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout"
        :cancel="hideTouchKeyboard" :accept="hideTouchKeyboard" :input="touchKeyboard.input" />
    </div>
  </div>
</template>

<script>
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";
import NumpadOnly from "../NumpadOnly";
import { mapGetters, mapState } from "vuex";
import validation from "../../../mixins/validation/validation";
import { Events } from '../../../plugins/events';

export default {
  name: "ButtonsGiftCardDialog",

  components: {
    NumpadOnly
  },

  props: {
    showDialog: Boolean,
    posType: String,
    giftCardID: String
  },

  data() {
    return {
      customButtons: [
        {
          name: '10euro',
          text: '10 ' + this.$store.getters['settings/currencyData']('symbol'),
          value: 10
        },
        {
          name: '25euro',
          text: '25 ' + this.$store.getters['settings/currencyData']('symbol'),
          value: 25
        },
        {
          name: '50euro',
          text: '50 ' + this.$store.getters['settings/currencyData']('symbol'),
          value: 50
        }
      ],
      voucherValue: 0,
      valid: true,
      scannedGiftCardID: "",
      awaitingGFID: null,
    }
  },
  mixins: [validation, KeyboardMixIns],
  computed: {
    ...mapGetters('pos/gastro', {
      gastroOpenItems: 'openItems',
    }),
    ...mapState(['pos']),
    isRFIDModuleActive() {
      return this.$store.getters['permissions/checkModule'](50);
    },
    computedGiftCardID() {
      if (this.giftCardID === undefined) {
        if (this.scannedGiftCardID.length > 0) {
          if (this.scannedGiftCardID.includes("GF$")) {
            let giftCardID = this.scannedGiftCardID.split("GF$");
            return giftCardID[1];
          } else {
            return this.scannedGiftCardID;
          }
        }
      } else {
        return this.giftCardID;
      }

      return "";
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.voucherValue = "";
        this.scannedGiftCardID = "";

        //CLEAR NUMPAD
        this.$refs.numpad.resetField();
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    scannedGiftCardID: function () {
      clearTimeout(this.awaitingGFID);

      this.awaitingGFID = setTimeout(() => {
        if (this.scannedGiftCardID.length > 0) {
          if (this.scannedGiftCardID.includes("GF$")) {
            let scannedGF = this.scannedGiftCardID.split("GF$");
            this.scannedGiftCardID = scannedGF[1];
          }
        }
      }, 800);
    },
  },

  methods: {
    isValideUUID(uuid) {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return regex.test(uuid);
    },
    closeDialog() {
      this.$refs.giftCardForm.reset();
      this.$emit("closeDialog");
    },
    numpadUpdateValue(value) {
      this.voucherValue = value;
    },
    numpadClickedCustomButton(customButton) {
      this.voucherValue = customButton.value;
    },
    numpadResetField() {
      this.voucherValue = "";
    },
    createVoucher() {
      //SET OUR NEW VALUES ON ITEM
      if (!this.$refs.giftCardForm.validate())
        return;

      let item = {
        id: "GIFTCARD_" + this.computedGiftCardID,
        ean: "GF$" + this.computedGiftCardID,
        name: "Gutscheinkarte " + this.computedGiftCardID,
        originalSellPrice: parseFloat(this.voucherValue.toString().replace(',', '.')),
        sellPrice: parseFloat(this.voucherValue.toString().replace(',', '.')),
        selectedExtras: [],
        mealSizeID: 0,
        weight: null,
        taxValue: 0,
        depositgroupID: 0,
        discount: 0,
        warenameOverwrite: null,
        individualBookingNo: null
      };

      let isAlreadyOrdered = false;

      if (this.posType === "gastro") {
        if (this.gastroOpenItems.length > 0) {
          //CHECK IF ITEM ALREADY ORDERED
          isAlreadyOrdered = this.gastroOpenItems.find(cartItem => {
            if (cartItem.id === item.id) return true;

            return false;
          });
        }
      } else if (this.posType === "retail") {
        if (this.pos.retail.orderedItems.length > 0) {
          //CHECK IF ITEM ALREADY ORDERED
          isAlreadyOrdered = this.pos.retail.orderedItems.find(cartItem => {
            if (cartItem.id === item.id) return true;

            return false;
          });
        }
      } else if (this.posType === "wholesaleRetail") {
        if (this.pos.wholesaleRetail.orderedItems.length > 0) {
          //CHECK IF ITEM ALREADY ORDERED
          isAlreadyOrdered = this.pos.wholesaleRetail.orderedItems.find(cartItem => {
            if (cartItem.id === item.id) return true;

            return false;
          });
        }
      }

      if (isAlreadyOrdered) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_giftCard_already_redeemed'),
          color: "warning"
        });

        this.closeDialog();
      } else {
        if (this.posType === "gastro") {
          this.$store.dispatch("pos/gastro/bookItem", item).then(() => {
            this.voucherValue = "";

            this.closeDialog();
          });

        } else if (this.posType === "retail") {
          this.$store.dispatch("pos/retail/bookItem", item).then(() => {
            this.voucherValue = "";

            this.closeDialog();
          });
        } else if (this.posType === "wholesaleRetail") {
          this.$store.dispatch("pos/wholesaleRetail/bookItem", item).then(() => {
            this.voucherValue = "";

            this.closeDialog();
          });
        }
      }
    }
  },
}
</script>
