<template>
  <v-dialog v-model="showDialog" persistent width="500">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                  indeterminate
                  color="primary"
                  width="3"
                  size="50"
              />

              <p style="margin-top: 10px; font-weight: bold; margin-bottom: 0;">{{ $t('generic.lang_waitingForEC') }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" v-if="$store.getters['permissions/checkPermission']('allowedCancelZVT') && !isRetailSelfCheckout" :disabled="!showProceedButton" @click="cancelDialog()">{{ $t('generic.lang_cancelPayment') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :disabled="!showProceedButton" @click="cancelPayment()">{{ $t('generic.lang_paymentSuccessful') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";

export default {
  name: "CashoutZVTDiaog",
  props: {
    isRetailSelfCheckout: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: false,
      cancelTimeout: null,
      showProceedButton: false,
      cancelSource: null,
      ecTerminal: null,
      amount: 0,
      receipt_id: 0,
      reversal_amount: 0.00,
    }
  },

  methods: {
    makePayment(amount) {
      this.amount = amount;

      this.showDialog = true;

      //GET EC TERMINAL
      if(this.ecTerminal === null) {
        this.getECTerminal(amount, 0,"makepayment");
        return;
      }

      //CREATE NEW AXIOS INSTANCE
      let zvtAxios = this.axios.create({
        baseURL: 'http://'+this.ecTerminal.zvtServerIP,
        timeout: 60000000,
        headers: {
          'terminal-id': this.ecTerminal.id,
          'terminal-ip': this.ecTerminal.terminalIP,
          'terminal-port': this.ecTerminal.terminalPort,
          'terminal-password': this.ecTerminal.terminalPW,
          'terminal-ecr': (this.ecTerminal.printOnECR !== 0),
        }
      });

      //CREATE CANCEL TIMEOUT
      this.cancelTimeout = setTimeout(() => {
        if(this.isRetailSelfCheckout){
          this.cancelPayment();
        }else {
          this.showProceedButton = true;
        }
      }, 60000);

      const CancelToken = this.axios.CancelToken;
      this.cancelSource = CancelToken.source();

      //MAKE REQUEST
      zvtAxios.post("/payment", {
        pay_value: this.amount
      }, {
        cancelToken: this.cancelSource.token
      }).then((res) => {
        if(res.status === 200) {
          this.showDialog = false;

          if(res.data.success) {
            this.finishPayment(res.data);
          }
          else {
            Events.$emit("showSnackbar", {
              message: res.data.message,
              color: "error"
            });

            this.resetDialog();
            this.$emit("cancel");
          }
        }
        else {
          Events.$emit("showSnackbar", {
            message: res.data.message,
            color: "error"
          });

          this.resetDialog();
          this.$emit("cancel");
        }
      }).catch((err) => {
        this.showDialog = false;
        this.$emit("cancel");

        // ZVT SERVER ERROR
        if(err.hasOwnProperty("response"))
          if(err.response && err.response.hasOwnProperty("data"))
            if(err.response.data && err.response.data.hasOwnProperty("message"))
              return Events.$emit("showSnackbar", {
                message: err.response.data.message,
                color: "error"
              });

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+": "+err.message,
          color: "error"
        });
      });
    },
    reversalPayment(receiptId,amount) {
      this.receipt_id = receiptId;
      this.reversal_amount = amount;

      this.showDialog = true;

      //GET EC TERMINAL
      if(this.ecTerminal === null) {
        this.getECTerminal(amount,receiptId,"reversal");
        return;
      }

      //CREATE NEW AXIOS INSTANCE
      let zvtAxios = this.axios.create({
        baseURL: 'http://'+this.ecTerminal.zvtServerIP,
        timeout: 60000000,
        headers: {
          'terminal-id': this.ecTerminal.id,
          'terminal-ip': this.ecTerminal.terminalIP,
          'terminal-port': this.ecTerminal.terminalPort,
          'terminal-password': this.ecTerminal.terminalPW,
          'terminal-ecr': (this.ecTerminal.printOnECR !== 0),
        }
      });

      //CREATE CANCEL TIMEOUT
      this.cancelTimeout = setTimeout(() => {
        this.showProceedButton = true;
      }, 60000);

      const CancelToken = this.axios.CancelToken;
      this.cancelSource = CancelToken.source();

      //MAKE REQUEST
      zvtAxios.post("/payment-reversal", {
        receipt_id: this.receipt_id,
        reversal_amount: this.reversal_amount
      }, {
        cancelToken: this.cancelSource.token
      }).then((res) => {
        if(res.status === 200) {
          this.showDialog = false;

          if(res.data.success) {
            this.finishPayment(res.data);
          }
          else {
            Events.$emit("showSnackbar", {
              message: res.data.message,
              color: "error"
            });

            this.resetDialog();
            this.$emit("cancel");
          }
        }
        else {
          Events.$emit("showSnackbar", {
            message: res.data.message,
            color: "error"
          });

          this.resetDialog();
          this.$emit("cancel");
        }
      }).catch((err) => {
        this.showDialog = false;
        this.$emit("cancel");

        // ZVT SERVER ERROR
        if(err.hasOwnProperty("response"))
          if(err.response && err.response.hasOwnProperty("data"))
            if(err.response.data && err.response.data.hasOwnProperty("message"))
              return Events.$emit("showSnackbar", {
                message: err.response.data.message,
                color: "error"
              });

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+": "+err.message,
          color: "error"
        });
      });
    },
    getECTerminal(amount,receipt_id,type) {
      this.axios.post(ENDPOINTS.POS.ACTIVEECTERMINAL).then((res) => {
        if(res.data.success) {
          this.ecTerminal = res.data.ecTerminal;

          if(type === "reversal") {
            this.reversalPayment(receipt_id,amount);
          }else{
            this.makePayment(amount);
          }
        }
        else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ecPaymentError'),
            color: "error"
          });

          this.resetDialog();
          this.$emit("cancel");
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_ecPaymentError')+" ("+err.message+")",
          color: "error"
        });

        this.resetDialog();
        this.$emit("cancel");
      });
    },
    cancelPayment() {
      this.cancelSource.cancel();

      this.finishPayment({msg: 'skipped'});
    },
    cancelDialog(data) {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_paymentError') + "!",
        color: "error"
      });

      this.cancelSource.cancel();
      this.resetDialog();
      this.$emit("cancel", data);
    },
    finishPayment(data) {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_paymentMade'),
        color: "success"
      });

      this.resetDialog();
      this.$emit("finished", data);
    },
    resetDialog() {
      this.showDialog = false;
      this.showProceedButton = false;

      clearTimeout(this.cancelTimeout);
    }
  }
}
</script>