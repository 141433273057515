import store from "../../store/index";
import lodash from "lodash";
import Vue from "vue";
import {geol} from "../../main";
import {format} from 'mathjs';
import {formatCurrency} from "../currencyFormater";
import {roundNumber} from "@/plugins/round";

// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
// we re gonna use this work arround for now until we find a better way
if(store.getters['settings/getSettingValue']('geoloc_systemcountry')){
    geol.locale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
}

export function createRetourePrintingData(items, data, printerOpts) {
    let printObj = [];
    let taxCalcObj = {};
    let totalprice = 0.00;

    //CALC TAX AND TOTAL
    //TAX DECIMAL => array(NET,TOTAL,TAXTOTAL)
    Array.from(items).forEach((item) => {
        //let calcNet = (format(item.sellPrice / (1 + (item.tax / 100)), {precision: 14}) * item.qty);
        //let calcTax = format((item.sellPrice * item.qty), {precision: 14}) - calcNet;



        let calcNet = roundNumber(parseFloat((format(roundNumber((item.sellPrice * item.qty),2) / (1 + (item.tax / 100)), {precision: 14}))),2);
        let calcTax = roundNumber(parseFloat((item.sellPrice * item.qty)),2) - roundNumber(calcNet,2);

        if (typeof taxCalcObj[item.tax] === 'undefined') {
            taxCalcObj[item.tax] = [];
            taxCalcObj[item.tax]['net'] = 0.00;
            taxCalcObj[item.tax]['total'] = 0.00;
            taxCalcObj[item.tax]['tax'] = 0.00;
        }

        taxCalcObj[item.tax]['net'] -= calcNet;
        //taxCalcObj[item.tax]['total'] -= format((item.sellPrice * item.qty), {precision: 14});
        taxCalcObj[item.tax]['total'] -= roundNumber(parseFloat((item.sellPrice * item.qty)),2);
        taxCalcObj[item.tax]['tax'] -= calcTax;

        totalprice -= roundNumber(parseFloat((item.sellPrice * item.qty)),2);
        //totalprice -= (item.sellPrice * item.qty);
    });


    let today = new Date(data['ts'] * 1000);
    let minutes = today.getMinutes();
    let hours = today.getHours();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    let printerTmpl = lodash.cloneDeep(store.getters["printer/templatesRetail"]);
    let printWidth = printerOpts.paperType;

    //DIRTY FIX!!!!!!!!!!!!! @dennis
    //FIND LAST CUT OF PRINT DATA(INDEX)
    let moneyGivenIndex = null;
    let moneyBackIndex = null;

    printerTmpl.forEach((element, index) => {
        if(element.hasOwnProperty("text")) {
            if(element.text.includes("@@PAYMENTTYPE@@"))
                moneyGivenIndex = index;
        }
    });

    if(moneyGivenIndex !== null)
        Vue.delete(printerTmpl, moneyGivenIndex);

    printerTmpl.forEach((element, index) => {
        if(element.hasOwnProperty("text")) {
            if(element.text.includes("@@MONEYBACK@@"))
                moneyBackIndex = index;
        }
    });

    if(moneyBackIndex !== null)
        Vue.delete(printerTmpl, moneyBackIndex);

    //LOOP TEMPLATE
    Array.from(printerTmpl).forEach((itemElements) => {
        if (itemElements["barcode128"]) {
            itemElements["barcode128"] = itemElements["barcode128"].replace("@@INVOICEID@@", data['saleID']);
            printObj.push(itemElements);

        } else if (itemElements["text"]) {
            itemElements["text"] = itemElements["text"].replace("@@INVOICEDATE@@", dd + "." + mm + "." + yyyy);
            itemElements["text"] = itemElements["text"].replace("@@INVOICETIME@@", hours + ":" + minutes);
            itemElements["text"] = itemElements["text"].replace("@@INVOICEID@@", data['saleID']);
            itemElements["text"] = itemElements["text"].replace("@@CASHIERID@@", data['cashierID']);

            // CHECK TABLE
            if (typeof data['tableId'] !== 'undefined' && itemElements.text.includes("@@TABLENO@@")) {
                if (itemElements.text.includes("@@TABLENO@@")) {
                    if ((data['tableId'].length === 0 || data['tableId'].toString() === "0")) {
                        return; // SKIP THIS TEXT ELEMENT
                    }
                }
            }
            if (typeof data['tableId'] === 'undefined' && itemElements.text.includes("@@TABLENO@@")) {
                itemElements["text"] = itemElements["text"].replace("@@TABLENO@@", "");
            }


            itemElements["text"] = itemElements["text"].replace("@@TABLENO@@", data['tableId']);
            itemElements["text"] = itemElements["text"].replace("@@SALESCLERKID@@", data['userID']);
            itemElements["text"] = itemElements["text"].replace("@@SALESTYPE@@", 'RETOURE');
            itemElements["text"] = itemElements["text"].replace("@@TOTAL@@", totalprice.toFixed(2).toString().replace(".", ","));

            printObj.push(itemElements);
        } else if (itemElements["shoppingCart"]) {
            Array.from(items).forEach((invoiceItem) => {
                if (printWidth === 50) {
                    let singlePrice = formatCurrency(Number(invoiceItem.sellPrice));
                    let totalsinglePrice = formatCurrency((parseFloat(invoiceItem.sellPrice) * -1) * parseFloat(invoiceItem.qty));

                    printObj.push({"align": "left"});
                    printObj.push({"text": "    " + invoiceItem.name.substr(0, 35)});
                    printObj.push({"align": "left"});
                    printObj.push({"text": "    " + (invoiceItem.qty.toString() + "X ") + ' ' + singlePrice + ' = ' + totalsinglePrice});
                    printObj.push({"feed": 1});
                } else {
                    let singlePrice = invoiceItem.sellPrice.toFixed(2).toString().replace(".", ",");
                    let totalsinglePrice = ((parseFloat(invoiceItem.sellPrice) * -1) * parseFloat(invoiceItem.qty)).toFixed(2).toString().replace(".", ",");

                    printObj.push({"text": (invoiceItem.qty.toString() + "X ").substr(0, 5).padEnd(5, ' ') + invoiceItem.name.substr(0, 22).padEnd(22, ' ') + " " + singlePrice.substr(0, 9).padEnd(9, ' ') + " " + totalsinglePrice.substr(0, 8).padEnd(8, ' ')});

                    if (invoiceItem.name.length > 22) {
                        printObj.push({"text": "     " + invoiceItem.name.substr(22, 80)});
                    }
                }
            });
        } else if (itemElements["taxElement"]) {
            if (printWidth === 50) {
                printObj.push({"align": "center"});
                printObj.push({"text": geol.t('generic.lang_taxOverview')});
                printObj.push({"left": "left"});

                Object.keys(taxCalcObj).forEach(function (element) {
                    printObj.push({"text": geol.t('accounting.lang_turnover')+" " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%   " + formatCurrency(taxCalcObj[element]['total'])});
                    printObj.push({"text": geol.t('generic.lang_subtotal')+" " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%   " + formatCurrency(taxCalcObj[element]['net'])});
                    printObj.push({"text": geol.t('accounting.lang_taxes')+" " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%   " + formatCurrency(taxCalcObj[element]['tax'])});
                });

                printObj.push({"feed": 1});
            } else {
                printObj.push({"align": "center"});
                printObj.push({"text": geol.t('generic.lang_taxOverview')});
                printObj.push({"left": "left"});
                printObj.push({"text": "ID       "+geol.t('accounting.lang_taxes')+"%       Total       Net       "+geol.t('accounting.lang_taxes')});
                var taxID = 0;

                Object.keys(taxCalcObj).forEach(function (element) {
                    taxID += 1;
                    printObj.push({"text": taxID + "       " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%       " + formatCurrency(taxCalcObj[element]['total']) + "   " + formatCurrency(taxCalcObj[element]['net']) + "   " + formatCurrency(taxCalcObj[element]['tax'])})
                });

                printObj.push({"feed": 1});
            }
        } else {
            printObj.push(itemElements);
        }
    });

    return printObj;
}