<template>
  <v-dialog v-model="showDialog" max-width="700px" persistent scrollable>
    <v-card :loading="storageCountLoading">
      <v-toolbar  dense flat>
        <v-toolbar-title>{{ $t('erp.lang_wareVariation') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="cancelDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="my-1"/>
      <v-card-text style="padding:0px !important;">
        <v-container>
          <v-row>
            <v-col class="pb-0" cols="12">
              <v-text-field v-model="search" :label="this.$t('datatables.lang_search')" dense outlined/>
            </v-col>
            <v-col class="pt-0" cols="12">
              <v-container>
                <v-row>
                  <v-col cols="3" style="padding-left:5px;padding-right:0px;">
                    {{
                      $t('generic.lang_name')
                    }}
                  </v-col>
                  <v-col class="text-center" cols="2" style="padding-left:0px;padding-right:0px;">
                    {{ $t('generic.lang_cashier_qty') }}
                  </v-col>
                  <v-col class="text-center" cols="2" style="padding-left:0px;padding-right:0px;">
                    {{ $t('generic.lang_size') }}
                  </v-col>
                  <v-col class="text-center" cols="2" style="padding-left:0px;padding-right:0px;">
                    {{ $t('erp.lang_warecreate_color') }}
                  </v-col>
                  <v-col class="text-right" cols="3" style="padding-left:0px;padding-right:5px;">
                    {{ $t('erp.lang_vk_brutto') }}
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" style="margin-top: -40px;">
              <v-card v-for="(variation) in filteredVariations"
                      :key="variation.id" color="#424242" dark
                      style="margin-bottom: 10px;" @click="selectVariation(variation)">
                <v-container>
                  <v-row>
                    <v-col cols="3" style="padding-left:5px;padding-right:0px;"><b>{{ variation.name }}</b></v-col>
                    <v-col v-if="variationStockCount(variation.id) !== null" class="text-center" cols="2"
                           style="padding-left:0px;padding-right:0px;">{{ variationStockCount(variation.id) }}
                    </v-col>
                    <v-col v-else class="text-center" cols="2" style="padding-left:0px;padding-right:0px;">
                      <v-progress-circular color="white" indeterminate size="20" width="2"></v-progress-circular>
                    </v-col>
                    <v-col class="text-center" cols="2" style="padding-left:0px;padding-right:0px;">
                      {{ variation.variationSize }}
                    </v-col>
                    <v-col class="text-center" cols="2" style="padding-left:0px;padding-right:0px;">
                      {{ variation.variationColor }}
                    </v-col>
                    <v-col class="text-right" cols="3" style="padding-left:0px;padding-right:5px;">
                      {{ variation.sellPrice | currency }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="cancelDialog">{{ $t('generic.lang_cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "../../../config";

export default {
  name: "ItemVariationsDialog",

  props: {
    showDialog: Boolean,
    item: Object
  },

  data() {
    return {
      storageCount: {},
      storageCountLoading: false,
      search: null,
    }
  },

  computed: {
    variations() {
      let variations = [];

      if (this.item === null)
        return variations;

      this.item.variations.forEach(async (variationID) => {
        let variation = await this.$store.dispatch("items/getItemByID", variationID);

        if (variation)
          variations.push(variation);
      })

      return variations;
    },
    variationsIDs() {
      if(!this.item){
        return [];
      }

      if(this.item && this.item.variations && this.item.variations.length<1){
        return [];
      }

      return this.item.variations;
    },
    filteredVariations(){
      if(!this.search || (this.search && this.search.length<1))
        return this.variations;

      return this.variations.filter((elt) => elt.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.getStorageCount();
      } else {
        this.storageCount = {};
      }
    }
  },

  methods: {
    cancelDialog() {
      this.$emit("cancelDialog");
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    selectVariation(variation) {
      this.$emit("selectVariation", variation);
    },
    variationStockCount(id) {
      if (!this.storageCount.hasOwnProperty(id))
        return null;

      return this.storageCount[id];
    },
    async getStorageCount() {
      this.storageCountLoading = true;
      await this.axios.post(ENDPOINTS.ERP.MULTIPLESTORAGECOUNT, {
        wareIDs: this.variationsIDs
      }).then((res) => {
        if (res.data.success) {
          this.storageCount = res.data.stockCount;
        }
      }).catch(() => {

      }).finally(()=>{
        this.storageCountLoading = false;
        this.$forceUpdate();
      })
    }
  }
}
</script>