<template>
  <div>
    <v-layout
      v-if="showEANField"
      wrap
    >
      <v-flex md6>
        <v-text-field
          dense
          @blur="$root.$emit('barcodeScanner.unpause')"
          @focus="$root.$emit('barcodeScanner.pause')"
          v-model="input"
          @keyup.enter="searchEAN"
          outlined
          placeholder="EAN"
        ></v-text-field>
      </v-flex>

      <v-flex md5>
        <v-btn
          @click="searchEAN"
          style="margin-top: 0px"
          height="40px"
          block
          :disabled="input.length === 0"
          >{{ $t('generic.lang_ok') }}</v-btn
        >
      </v-flex>
    </v-layout>

    <v-layout
      :style="{ marginTop: showEANField ? '-15px' : '' }"
      wrap
    >
      <!-- Custom Buttons -->
      <v-flex
        v-for="customButton in customButtons"
        :key="customButton.name"
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickCustomButton(customButton)"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">{{ customButton.text }}</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('7')"
          id="customButton-7"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">7</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('8')"
          id="customButton-8"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">8</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('9')"
          id="customButton-9"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">9</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('4')"
          id="customButton-4"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">4</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('5')"
          id="customButton-5"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">5</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('6')"
          id="customButton-6"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">6</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('1')"
          id="customButton-1"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">1</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('2')"
          id="customButton-2"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">2</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('3')"
          id="customButton-3"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">3</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('0')"
          tile
          id="customButton-0"
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">0</p>
        </v-card>
      </v-flex>

      <v-flex
        v-if="!disableComma"
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="clickButton('00')"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">00</p>
        </v-card>
      </v-flex>

      <v-flex
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          @click="resetField"
          tile
          ripple
          flat
          height="100%"
        >
          <p
            class="numpadText"
            style="color: #d92550"
          >
            CLR
          </p>
        </v-card>
      </v-flex>
      <v-flex
        v-if="showControlButtons"
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          :disabled="isQtyDisabled"
          :color="activeCTLBtn === 1 ? '#3AC47D' : ''"
          @click="selectCTLBtn(1)"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">{{ $t('generic.lang_cashier_qty') }}</p>
        </v-card>
      </v-flex>

      <v-flex
        v-if="showControlButtons"
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          :color="activeCTLBtn === 2 ? '#3AC47D' : ''"
          @click="selectCTLBtn(2)"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">{{ $t('generic.lang_PLUCashier') }}</p>
        </v-card>
      </v-flex>

      <v-flex
        v-if="canChangePrice"
        style="padding: 1px"
        xs4
        md4
      >
        <v-card
          :color="activeCTLBtn === 3 ? '#3AC47D' : ''"
          @click="selectCTLBtn(3)"
          tile
          ripple
          flat
          height="100%"
        >
          <p class="numpadText">{{ $t('generic.lang_cashier_price') }}</p>
        </v-card>
      </v-flex>
    </v-layout>

    <BookItem
      ref="bookItem"
      :item="item"
      :itemgroup="itemgroup"
      :pos-type="posType"
      @itemBooked="itemBooked"
    ></BookItem>
  </div>
</template>

<style scoped>
@media only screen and (max-width: 1100px) {
  .numpadText {
    font-family: Anton;
    font-size: 15px;
    text-align: center;
    padding-top: 10%;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }
}

@media only screen and (min-width: 1101px) {
  .numpadText {
    font-family: Anton;
    font-size: 20px;
    text-align: center;
    padding-top: 10%;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
  }
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import BookItem from '../../mixins/pos/BookItem';
import {ENDPOINTS} from "../../config";

export default {
  name: 'Numpad',

  components: {
    BookItem,
  },

  props: {
    showControlButtons: {
      default: false,
      type: Boolean,
    },
    disableComma: {
      default: false,
      type: Boolean,
    },
    customButtons: {
      default: () => {
        return [];
      },
      type: Array,
    },
    showEANField: {
      default: false,
      type: Boolean,
    },
    posType: String,
  },

  data() {
    return {
      activeCTLBtn: null,
      input: '',
      qtyChange: '',
      item: null,
      itemgroup: null,
    };
  },

  computed: {
    ...mapState([
        'pos',
        'app'
    ]),
    selectedItem() {
      if (this.posType === "retail") {
        if (this.pos.retail.selectedItem !== null) {
          return this.pos.retail.selectedItem;
        }
      }
      
      if (this.posType === "wholesaleRetail") {
        if (this.pos.wholesaleRetail.selectedItem !== null) {
          return this.pos.wholesaleRetail.selectedItem;
        }
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.selectedOpenItem !== null) {
          return this.pos.gastro.selectedOpenItem;
        } else if (this.pos.gastro.selectedOrderedItem !== null) {
          return this.pos.gastro.selectedOrderedItem;
        }
      }

      return null;
    },
    retailSelectedItem() {
      return this.pos.retail.selectedItem;
    },
    wholesaleRetailSelectedItem() {
      return this.pos.wholesaleRetail.selectedItem;
    },
    gastroSelectedOpenItem() {
      return this.pos.gastro.selectedOpenItem;
    },
    gastroSelectedOrderedItem() {
      return this.pos.gastro.selectedOrderedItem;
    },
    isQtyDisabled() {
      if (this.posType === 'retail' && this.retailSelectedItem) {
        return (
          this.retailSelectedItem.serialNo &&
          this.retailSelectedItem.serialNo.length > 1
        );
      } else if (this.posType === 'gastro' && this.gastroSelectedOpenItem) {
        return (
          this.gastroSelectedOpenItem.serialNo &&
          this.gastroSelectedOpenItem.serialNo.length > 1
        );
      } else if (this.posType === 'wholesaleRetail' && this.wholesaleRetailSelectedItem) {
        return (
          this.wholesaleRetailSelectedItem.serialNo &&
          this.wholesaleRetailSelectedItem.serialNo.length > 1
        );
      } else return false;
    },
    canChangePrice(){
      if(this.selectedItem && this.selectedItem.priceCannotChange)
        return false;

      return this.showControlButtons && this.$store.getters['permissions/checkPermission']('allowChangePrice')
    }
  },

  watch: {
    activeCTLBtn() {
      this.qtyChange = '';
    },
    wholesaleRetailSelectedItem() {
      this.qtyChange = '';
    },
    retailSelectedItem() {
      this.qtyChange = '';
    },
    gastroSelectedOpenItem() {
      this.qtyChange = '';
    },
    gastroSelectedOrderedItem() {
      this.qtyChange = '';
    },
  },

  methods: {
    selectCTLBtn(button) {
      if (this.activeCTLBtn === button) {
        this.activeCTLBtn = null;
      } else {
        this.activeCTLBtn = button;
      }
    },
    clickButton(button) {
      this.$emit('clickButton', button);

      if (this.activeCTLBtn === 1 && this.isQtyDisabled) this.activeCTLBtn = 0;
      //CHANGE QTY
      if (this.activeCTLBtn === 1 && !this.isQtyDisabled) {
        this.qtyChange += button;

        if (this.posType === 'retail') {
          this.$store.dispatch('pos/retail/numpadQTY', this.qtyChange);
        }
        
        if (this.posType === 'wholesaleRetail') {
          this.$store.dispatch('pos/wholesaleRetail/numpadQTY', this.qtyChange);
        }

        if (this.posType === 'gastro') {
          this.$store.dispatch('pos/gastro/numpadQTY', this.qtyChange);
        }
      }

      //PLU INPUT
      if (this.activeCTLBtn === 2) {
        this.input += button;
      }

      //CHANGE PRICE
      if (this.activeCTLBtn === 3) {
        let tmp = this.qtyChange.toString().split('.');
        if (Number(tmp[0]) === 0 && tmp[1] === undefined) {
          this.qtyChange = (Number(button) / 100).toFixed(2);
        } else if (button === '00') {
          this.qtyChange = (Number(this.qtyChange) * 100).toFixed(2);
        } else {
          tmp = Number(this.qtyChange) * 10;
          tmp += Number(button) / 100;
          this.qtyChange = tmp.toFixed(2);
        }

        //this.qtyChange = temp;

        if (this.posType === 'retail') {
          this.$store.dispatch(
            'pos/retail/numpadPrice',
            parseFloat(this.qtyChange)
          );
        }
        
        if (this.posType === 'wholesaleRetail') {
          this.$store.dispatch(
            'pos/wholesaleRetail/numpadPrice',
            parseFloat(this.qtyChange)
          );
        }

        if (this.posType === 'gastro') {
          this.$store.dispatch(
            'pos/gastro/numpadPrice',
            parseFloat(this.qtyChange)
          );
        }
      }
    },
    clickCustomButton(customButton) {
      this.$emit('clickCustomButton', customButton);
    },
    resetField() {
      this.$emit('resetField');

      if (this.activeCTLBtn === 1) {
        if (this.posType === 'retail') {
          this.$store.dispatch('pos/retail/numpadQTY', 0);
        }
        
        if (this.posType === 'wholesaleRetail') {
          this.$store.dispatch('pos/wholesaleRetail/numpadQTY', 0);
        }

        if (this.posType === 'gastro') {
          this.$store.dispatch('pos/gastro/numpadQTY', 0);
        }
      }

      //PRICE
      if (this.activeCTLBtn === 3) {
        if (this.posType === 'retail') {
          this.$store.dispatch('pos/retail/numpadPrice', 0);
        }
        
        if (this.posType === 'wholesaleRetail') {
          this.$store.dispatch('pos/wholesaleRetail/numpadPrice', 0);
        }

        if (this.posType === 'gastro') {
          this.$store.dispatch('pos/gastro/numpadPrice', 0);
        }
      }

      this.input = '';
      this.qtyChange = '';
    },
    addGFOrV(prefix) {
      //ADD GF$ OR V$
      this.input = prefix;
    },
    searchEAN() {
      if(this.app.useAPIForPOSActions && (this.posType === "retail" || this.posType === "wholesaleRetail") && !this.input.includes("V$") && !this.input.includes("C$") && !this.input.includes("GF$") && !this.input.includes("CA$") && !this.input.includes("CC$")  && !this.input.includes("R$")) {
        this.axios.post(ENDPOINTS.POS.RETAIL.SCAN, {
          query: this.input
        }).then((res) => {
          if(res.data.success)
            this.$refs.bookItem.selectItemByData(res.data.itemgroup, res.data.item, res.data.overwrite.weight);
        });
      }else {
        this.$refs.bookItem.selectItemEAN(this.input);
      }

      //RESET INPUT FIELD
      this.input = '';
    },
    itemBooked() {
      //CLEAR DATA
      this.input = '';
      this.item = null;
      this.itemgroup = null;
    },
    initSettings() {
      let tmp =
        this.$store.getters['settings/getSettingValue']('defaultPLUValue');
      if (tmp) {
        switch (tmp) {
          case 'PLU':
            this.selectCTLBtn(2);
            break;
          case 'PRICE':
            this.selectCTLBtn(3);
            break;
          default:
            this.selectCTLBtn(1);
        }
      } else {
        this.selectCTLBtn(1);
      }
    },
    keyDownListener(e) {
        //IGNORE PLU!!! IMPORTANT!!!
        if(this.activeCTLBtn !== 2) {
            const overlay = document.querySelector('.v-overlay--active');
            if (document.hasFocus() && !overlay && e.target.tagName !== 'INPUT') {
                e.stopPropagation();
                // allowed key codes for numbers in javascript ( 0 - 9)
                const allowedKeyCodes = [
                    97, 98, 99, 100, 101, 102, 103, 104, 105, 96, 13,
                ];
                // check if key is a number and not by barcode scanner
                if (
                    e.key >= 0 &&
                    e.keyCode !== 13 &&
                    allowedKeyCodes.includes(e.keyCode) &&
                    !e.ctrlKey
                ) {
                    // click the button
                    const btn = document.getElementById('customButton-' + e.key);
                    // simulate the actual click
                    btn.click();
                    return false;
                } else if (
                    e.keyCode === 13 &&
                    !e.ctrlKey &&
                    allowedKeyCodes.includes(e.keyCode) &&
                    this.activeCTLBtn === 2
                ) {
                    this.searchEAN();
                    return false;
                }
            }
        }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDownListener);
  },
  mounted() {
    this.initSettings();
    document.addEventListener('keydown', this.keyDownListener);
  },
};
</script>
