<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        dense
        flat
      >
        <v-toolbar-title
          >{{ $t('erp.lang_posItemName') }} /
          {{ $t('erp.lang_bookingNumber') }}</v-toolbar-title
        >

        <v-spacer />

        <v-btn
          icon
          @click="cancelDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="padding-top: 40px">
        <v-text-field
          ref="allowSetName"
          autofocus
          v-if="item.allowSetName === 1"
          :label="$t('erp.lang_posItemName')"
          :data-layout="KEYBOARD.KEYSETS.NORMAL"
          @focus="showTouchKeyboard"
          @keyup="checkPatternName"
          outlined
          dense
          v-model="name"
          @keyup.enter="dataSaved"
        ></v-text-field>
        <v-text-field
          v-if="item.bookingNumberReq === 1"
          autofocus
          :label="$t('erp.lang_bookingNumber')"
          :data-layout="KEYBOARD.KEYSETS.NORMAL"
          @focus="showTouchKeyboard"
          @keyup="checkPatternBooking"
          outlined
          dense
          v-model="bookingNumber"
          @keyup.enter="dataSaved"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="cancelDialog"
          >{{ $t('generic.lang_cancel') }}</v-btn
        >
        <v-spacer />
        <v-btn
          color="success"
          @click="dataSaved"
          :disabled="this.cantSaveData"
          >{{ $t('generic.lang_save') }}</v-btn
        >
      </v-card-actions>

      <div
        id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard"
        style="z-index: 299 !important"
      >
        <vue-touch-keyboard
          v-if="touchKeyboard.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import validation from '../../../mixins/validation/validation';
import KeyboardMixIns from "../../../mixins/KeyboardMixIns";

export default {
  name: 'ItemWarenameBookingnumberDialog',

  props: {
    item: Object,
    showDialog: Boolean,
  },
  mixins: [validation, KeyboardMixIns],
  data() {
    return {
      name: '',
      bookingNumber: '',
      bookingNoRegExp: '',
    };
  },
  computed: {
    ...mapState(['touchkeyboard']),
    cantSaveData() {
      return (
        (this.item?.bookingNumberReq === 1 &&
          this.bookingNumber.length === 0) ||
        (this.item?.allowSetName === 1 && this.name.length < 2)
      );
    },
  },
  watch: {
    showDialog(val) {
      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (!val) {
        this.$root.$emit('barcodeScanner.unpause')
        this.name = '';
        this.bookingNumber = '';
      } else {
        // SET OLD NAME
        this.$root.$emit('barcodeScanner.pause')
        if (this.item !== null) this.name = this.item.name;
      }
    },
  },

  mounted() {
    if (this.item !== null) this.name = this.item.name;
    this.bookingNoRegExp = this.item.bookingNoRegExp;
  },
  destroyed() {
    this.$root.$emit('barcodeScanner.unpause')
  },
  methods: {
    checkPatternName() {
      this.name = this.name.replace(this.preventSpecialCharsRegex, '');
    },
    checkPatternBooking() {
      if (!this.bookingNoRegExp || this.bookingNoRegExp.length < 3) {
        this.bookingNumber = this.bookingNumber.replace(
          this.preventSpecialCharsRegex,
          ''
        );
      } else {
        this.bookingNumber = this.bookingNumber.replace(
          this.regExpFromString(this.bookingNoRegExp),
          ''
        );
      }
    },
    regExpFromString(str) {
      let flags = str.replace(/.*\/([gimuy]*)$/, '$1');
      if (flags === str) flags = '';
      let pattern = flags
        ? str.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1')
        : str;
      try {
        return new RegExp(pattern, flags);
      } catch (e) {
        return null;
      }
    },
    cancelDialog() {
      this.$emit('cancelDialog');
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    dataSaved() {
      if (this.cantSaveData) return;
      this.$emit('dataSaved', {
        name: this.name,
        bookingNumber: this.bookingNumber,
      });
    },
  },
};
</script>
